@import '../type.scss';

.p404 {
  h1 {
    @include raleway-bold(60px, 36px);
    margin: 0 0 .5em;
    text-align: center;

    &::after {
      $aspect-ratio: 1118 / 622;
      content: '';
      display: block;
      background-image: url('../../../../i/perry.png');
      background-repeat: no-repeat;
      margin: 50px auto 0;
      background-size: 100%;
      height: 0;
      z-index: -1;
      pointer-events: none;

      @include desktop-and-tablet-landscape {
        $width: percentage(250px / $desktop-inner);
        width: $width;
        padding-top: $width * $aspect-ratio;
      }

      @include tablet-portrait-and-phone {
        $width: percentage(250px / $tablet-inner);
        width: $width;
        padding-top: $width * $aspect-ratio;
      }
    }
  }

  .background {
    $aspect-ratio: 729px / 1295px;
    top: 0;
    left: 0;

    @include desktop-and-tablet-landscape {
      $width: percentage(1295px / $desktop-inner);
      @include background($width, $aspect-ratio, 'fourohfour.svg');
      margin-left: percentage(-115px / $desktop-inner);
      margin-top: percentage(-145px / $desktop-inner);
    }

    @include tablet-portrait-and-phone {
      $width: percentage(1350px / $tablet-inner);
      @include background($width, $aspect-ratio, 'fourohfour.svg');
      margin-left: percentage(-100px / $tablet-inner);
      margin-top: percentage(-100px / $tablet-inner);
    }
  }
}
