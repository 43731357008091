@import '../constants';
@import '../type';

.colourful-carousel {
  .flickity-viewport {
    display: flex;  // carousel-item's min-height: 100%;
  }

  .carousel-item {
    $title-font-size: 28px;
    position: relative;
    min-height: 100%;

    @include desktop-and-tablet-landscape {
      $percent-margin: percentage(15px / $desktop-inner);
      @include responsive-font-size($title-font-size, 18px);
      $padding-left: percentage($m6 / $desktop-inner);
      $padding-right: percentage(50px / $desktop-inner);
      padding: percentage($m5 / $desktop-inner) $padding-right percentage($m2 / $desktop-inner) $padding-left;
      $group-by: 4;
      $width: percentage(1 / $group-by) - ($percent-margin * ($group-by - 1) / $group-by);
      width: $width;
      margin-left: $percent-margin;
      @for $i from 1 through $group-by {
        &:nth-child(#{$i}) {
          z-index: -$i;
        }
      }

      &:first-child {
        margin-left: 0;
      }

      $carousel-item-inner: ($width - $padding-left - $padding-right) / 100% * $desktop-inner;

      .item-title {
        margin: 0 0 percentage($m6 / $carousel-item-inner);
        padding-bottom: percentage($m6 / $carousel-item-inner);
      }

      .item-background-image {
        margin-top: percentage($m6 / $carousel-item-inner);
      }
    }

    @include tablet-portrait {
      $percent-margin: percentage(10px / $tablet-inner);
      @include responsive-font-size(25px, 21px, $tablet-portrait);
      $padding-left: percentage($m6 / $tablet-inner);
      $padding-right: percentage($m5 / $tablet-inner);
      padding: percentage($m6 / $tablet-inner) $padding-right
               percentage($m2 / $tablet-inner) $padding-left;
      $group-by: 3;
      $width: percentage(1 / $group-by) - ($percent-margin * ($group-by - 1) / $group-by);
      width: $width;
      margin-left: $percent-margin;
      @for $i from 1 through $group-by {
        &:nth-child(#{$i}) {
          z-index: -$i;
        }
      }

      &:first-child {
        margin-left: 0;
      }

      $carousel-item-inner: ($width - $padding-left - $padding-right) / 100% * $tablet-inner;

      .item-title {
        margin: 0 0 percentage(20px / $carousel-item-inner);
        padding-bottom: percentage(20px / $carousel-item-inner);
      }

      .item-background-image {
        margin-top: percentage(20px / $carousel-item-inner);
      }
    }

    @include phone-landscape {
      $percent-margin: percentage(10px / $tablet-inner);
      @include responsive-font-size($title-font-size, 18px, $tablet-portrait);
      $group-by: 2;
      $padding-left: percentage($m6 / $tablet-inner);
      $padding-right: percentage($m5 / $tablet-inner);
      padding: percentage($m6 / $tablet-inner) $padding-right
               percentage($m2 / $tablet-inner) $padding-left;
      $width: percentage(1 / $group-by) - ($percent-margin * ($group-by - 1) / $group-by);
      width: $width;
      margin-left: $percent-margin;
      @for $i from 1 through $group-by {
        &:nth-child(#{$i}) {
          z-index: -$i;
        }
      }

      &:first-child {
        margin-left: 0;
      }

      $carousel-item-inner: ($width - $padding-left - $padding-right) / 100% * $tablet-inner;

      .item-title {
        margin: 0 0 percentage(20px / $carousel-item-inner);
        padding-bottom: percentage(20px / $carousel-item-inner);
      }

      .item-background-image {
        margin-top: percentage(20px / $carousel-item-inner);
      }
    }

    @include phone-portrait {
      $percent-margin: percentage(10px / $phone-inner);
      @include responsive-font-size(32px, 26px, $phone-design-width);
      $group-by: 1;
      $padding-left: percentage($m6 / $phone-inner);
      $padding-right: percentage($m5 / $phone-inner);
      padding: percentage($m6 / $phone-inner) $padding-right $m3 $padding-left;
      $width: percentage(1 / $group-by) - ($percent-margin * ($group-by - 1) / $group-by);
      width: $width;
      margin-left: $percent-margin;
      @for $i from 1 through $group-by {
        &:nth-child(#{$i}) {
          z-index: -$i;
        }
      }

      &:first-child {
        margin-left: 0;
      }

      $carousel-item-inner: ($width - $padding-left - $padding-right) / 100% * $phone-inner;

      .item-title {
        margin: 0 0 percentage(20px / $carousel-item-inner);
        padding-bottom: percentage(20px / $carousel-item-inner);
      }

      .item-background-image {
        margin-top: percentage(20px / $carousel-item-inner);
      }
    }

    .background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }

    .idx {
      @include raleway-bold;
      $idx-font-size: 18px;
      font-size: ($idx-font-size / $title-font-size) * 1em;
      position: absolute;
      // Size all these relative to $title-font-size so it's easier to make
      // sure idx and item-title don't clash.
      top: ($title-font-size / $idx-font-size) * .4em;
      right: ($title-font-size / $idx-font-size) * .4em;
      width: ($title-font-size / $idx-font-size) * 1.2em;
      height: ($title-font-size / $idx-font-size) * 1.2em;
      line-height: ($title-font-size / $idx-font-size) * 1.1em;
      border-radius: 50%;
      text-align: center;
    }

    .item-title {
      @include feijoa-bold;
      // avoid clash with idx
      padding-right: .6em;
      border-bottom: 1px solid;
      line-height: 1;
      min-height: 2.7em;
    }

    .item-detail {
      @include feijoa-medium;
      font-size: (19px / $title-font-size) * 1em;
      line-height: 1.4;
      margin: 0;
    }

    .item-background-image {
      margin-left: 10%;
      margin-right: 10%;
    }
  }

  .flickity-prev-next-button {
    visibility: hidden;
  }
}
