@import 'c/main/src/constants';
@import 'c/main/src/type';

@import '../type.scss';

$static-max-width: 1170px;

.static-page {
  margin-bottom: 50vh;

  .static-inner {
    @include desktop-and-tablet {
      max-width: $static-max-width;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .cms-html {
    @include feijoa-medium;
    margin-top: $m2;

    h3,
    h4 {
      @include responsive-font-size(34px, 25px, $static-max-width);
      margin: ($m4 / 34px) * 1em 0;
    }

    h4 {
      @include raleway-medium;
      margin: ($m4 / 34px) * 1em 0;
    }

    h5 {
      @include responsive-font-size(28px, 23px, $static-max-width);
      margin: ($m5 / 30px) * 1em 0;
    }

    p {
      @include responsive-font-size(23px, 19px, $static-max-width);
      margin: ($m6 / 23px) * 1em 0;
    }

    h6 {
      @include responsive-font-size(18px, 14px, $static-max-width);
      margin: ($m6 / 18px) * 1em 0;
    }

    blockquote {
      margin: 0;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        @include raleway-medium;
      }
    }
  }
}
