@import 'c/main/lib/mixins';
@import 'c/main/src/constants';
@import 'c/main/src/type';

$holding-tan: #F4E7D8;
$holding-orange: #FF5D39;
$holding-pink: #FFAFB8;
$holding-yellow: #F6C05D;

$holding-phone-margin: 20px;
$holding-phone-inner: $phone-design-width - ($holding-phone-margin * 2);
$holding-footer-height: 200px;
$holding-footer-phone-bottom-space: 100px;

.holding-page {
  min-height: 100vh;

  @include desktop-and-tablet {
    background-color: $holding-yellow;
  }

  @include phone {
    background-color: $holding-tan;
    padding: percentage($holding-phone-margin / $phone-design-width) 0
             $holding-footer-phone-bottom-space;
  }

  .holding-inner {
    position: relative;
    background-color: $holding-tan;
  }

  $text-panel-width: 690px;
  $text-panel-tablet-width: 431px;
  $background-width: $design-max - $text-panel-width;
  .background {
    position: absolute;
    top: 0;
    right: 0;

    @include desktop-and-tablet {
      bottom: 0;
    }

    @include desktop-and-tablet-landscape {
      left: percentage($text-panel-width / $design-max);
    }

    @include tablet-portrait {
      left: $text-panel-tablet-width;
    }

    @include phone {
      left: 0;
      bottom: ($holding-footer-phone-bottom-space + $holding-footer-height) * -1;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: $holding-pink;
      background-size: 100% 50%;
      background-position: bottom left;
      z-index: 1;

      @include desktop-and-tablet {
        height: 0;
        padding-top: percentage(1 / 3);
      }

      @include phone {
        height: $holding-footer-phone-bottom-space + $holding-footer-height;
      }
    }

    .rainbow-cloud {
      $rainbow-cloud-width: percentage(282px / $background-width);
      position: absolute;
      left: 0;
      margin-top: percentage(-20px / $background-width);
      background-image: url('../../../../i/decoration/rainbowcloud.svg');
      background-size: 100%;
      background-repeat: no-repeat;
      z-index: 4;

      @include desktop-and-tablet-landscape {
        width: $rainbow-cloud-width;
        height: 0;
        padding-top: 307px / 282px * $rainbow-cloud-width;
        top: 0;
      }

      @include tablet-portrait {
        width: 176px;
        height: 192px;
        top: 0;
      }

      @include phone {
        @include raleway-extra-bold(50px, 26px, $phone-max);
        top: 2em;
        width: percentage(137px / $phone-design-width);
        height: 0;
        padding-top: percentage(150px / $phone-design-width);
        margin-top: percentage($holding-phone-margin / $phone-design-width);
      }
    }

    .holding-wave {
      position: absolute;
      left: 0;
      background-image: url('../../../../i/holdingwave.svg');
      background-size: 100% auto;
      background-position: bottom right;
      background-repeat: no-repeat;
      z-index: 1;

      @include desktop-and-tablet {
        bottom: 0;
        margin-bottom: 10%;
      }

      @include desktop-and-tablet-landscape {
        width: 100%;
        height: 0;
        padding-top: percentage(643px / 1090px);
      }

      @include tablet-portrait {
        width: 469px;
        height: 276px;
      }

      @include phone {
        bottom: $holding-footer-phone-bottom-space + $holding-footer-height;
        height: 276px;
        right: 0;
      }
    }
  }

  $text-panel-padding: 65px;
  $text-panel-inner: $text-panel-width - ($text-panel-padding * 2);
  $text-panel-phone-padding: 45px;
  .text-panel {
    position: relative;
    background-color: $holding-orange;
    color: $holding-tan;
    text-align: center;
    z-index: 2;

    @include desktop-and-tablet {
      padding: percentage(80px / $design-max)
               percentage($text-panel-padding / $design-max)
               percentage(160px / $design-max);
    }

    @include desktop-and-tablet-landscape {
      width: percentage($text-panel-width / $design-max);
    }

    @include tablet-portrait {
      width: $text-panel-tablet-width;
    }

    @include phone {
      margin: 0 percentage($holding-phone-margin / $phone-design-width);
      padding: percentage($text-panel-phone-padding / $phone-design-width)
               percentage($text-panel-phone-padding / $phone-design-width)
               percentage(100px / $phone-design-width);
    }

    .text-panel-inner {
      margin: 0 auto;
      max-width: $text-panel-inner;
    }

    h1,
    .cms-html {
      @include desktop-and-tablet {
        margin: 0 0 percentage(80px / $text-panel-inner);
      }
    }

    h1,
    .cms-html p {
      line-height: 1.05;
      letter-spacing: -0.013em;

      @include above-design-max {
        @include raleway-extra-bold(38px);
      }

      @include desktop-and-tablet {
        @include raleway-extra-bold(38px, 20px);
      }
    }

    h1 {
      @include phone {
        @include raleway-extra-bold(50px, 26px, $phone-max);
        margin: 0 0 percentage(240px / $holding-phone-inner);
      }
    }

    .cms-html {
      @include phone {
        margin: 0 0 percentage(50px / $holding-phone-inner);
        text-align: left;
      }
    }

    .cms-html p {
      @include phone {
        @include raleway-extra-bold(34px, 17px, $phone-max);
        line-height: 1.35;
      }
    }

    .subscribe {
      .subscribe-form {
        label {
          display: none;
        }
      }

      .form-field.email_address,
      .join-us {
        @include desktop-and-tablet {
          display: inline-block;
        }
      }

      .form-field.email_address input,
      .join-us,
      .message {
        @include raleway-semi-bold(16px);
        font-weight: 700;
        line-height: 1.25;
      }

      .form-field.email_address input,
      .join-us {
        padding: 1em;
        border: 1px solid $holding-tan;
      }

      .form-field.email_address {
        @include desktop-and-tablet {
          width: percentage(310px / $text-panel-inner);
        }

        input {
          @include autofill($holding-orange, $holding-tan);
          width: 100%;
        }
      }

      .join-us {
        @include opacity-anchor-style;
        background: $holding-tan;
        color: $holding-orange;

        @include desktop-and-tablet {
          width: percentage(250px / $text-panel-inner);
        }

        @include phone {
          width: 100%;
        }
      }

      .message {
        margin: 1em 0 0;
      }
    }
  }

  .social {
    position: absolute;
    z-index: 3;

    @include desktop-and-tablet {
      right: 0;
      bottom: 0;
      margin: 0 0 25px 25px;
    }

    @include desktop-and-tablet-landscape {
      left: percentage($text-panel-width / $design-max);
    }

    @include tablet-portrait {
      left: $text-panel-tablet-width;
    }

    @include phone {
      top: 100%;
      right: percentage($holding-phone-margin / $phone-design-width);
      margin: 15px 15px 0 0;
    }

    a {
      @include opacity-anchor-style;
      display: inline-block;
      padding: 10px;
      margin-right: 5px;
    }
  }
}

.holding-footer {
  position: relative;
  background-color: $holding-yellow;
  z-index: 2;

  @include desktop-and-tablet {
    padding-top: percentage($holding-footer-height / $design-max);
  }

  @include phone {
    padding-top: $holding-footer-height;
    margin: 0 percentage($holding-phone-margin / $phone-design-width);
  }

  .cloud-tears {
    position: absolute;
    top: 0;
    height: 0;
    background-image: url('../../../../i/decoration/cloudtears.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 3;

    @include desktop-and-tablet {
      $cloud-tears-width: percentage(160px / $design-max);
      width: $cloud-tears-width;
      padding-top: 171px / 160px * $cloud-tears-width;
      left: percentage(50px / $design-max);
      margin-top: percentage(-75px / $design-max);
    }

    @include phone {
      width: percentage(100px / $holding-phone-inner);
      height: 0;
      padding-top: percentage(107px / $holding-phone-inner);
      left: percentage(10px / $holding-phone-inner);
      margin-top: percentage(-47px / $holding-phone-inner);
    }
  }
}
