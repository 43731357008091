@import '../constants';
@import '../type';

.product-detail-page {
  @include clearfix;
  padding-bottom: 0 !important;

  @include phone {
    display: flex;
    flex-direction: column;

    &.standard-page {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
    }
  }

  .product-detail-header,
  .hero,
  .cms-html,
  .product-about-section,
  .product-inaction-section,
  .scrapbook-section,
  .reviews-section,
  .available-books-section {
    @include phone {
      padding-left: percentage($phone-margin / $phone-design-width);
      padding-right: percentage($phone-margin / $phone-design-width);
    }
  }

  $cms-inner: 450px - 20px;
  $header-inner: 310px;
  $hero-width: 680px;

  .product-detail-header,
  .cms-html {
    @include desktop-and-tablet {
      float: right;
      width: percentage(590px / $desktop-inner);
      margin-left: auto;
    }
  }

  .product-detail-header {
    @include phone {
      order: 0;
      margin-bottom: 1em;
    }

    .product-title {
      @include raleway-bold(50px, 42px);
      line-height: 1;
      margin: 0 0 .5em;

      @include phone {
        margin-bottom: .5em;
        padding-bottom: 0;
        width: 60%;
      }
    }

    .product-tagline {
      @include raleway-medium(23px, 19px);
      line-height: 1.2;
      margin: 0 0 .5em;

      @include phone {
        margin-bottom: 1em;
      }
    }
  }

  .cms-html,
  section {
    @include phone {
      margin: 0 0 percentage($m4 / $phone-inner);
    }
  }

  .cms-html.book-options,
  .hero {
    @include desktop-and-tablet {
      margin: 0 0 percentage($m2 / $desktop-inner);
    }
  }

  .cms-html {
    .header-inner {
      @include desktop-and-tablet {
        padding: 1em 0;
        border-top: 1px solid;

        &:first-child {
          margin-top: 1em;
        }
      }
    }
  }

  .hero {
    @include desktop-and-tablet {
      float: left;
      width: percentage($hero-width / $desktop-inner);
    }

    @include phone {
      order: -1;
      margin: $m6 0 percentage($m4 / $phone-inner);
    }

    .hero-image-carousel {
      width: 100%;

      .carousel-item {
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        @include phone {
          @include force-square-image;
        }
      }

      .flickity-prev-next-button {
        display: none;
      }

      .flickity-page-dots {
        @include desktop-and-tablet {
          display: none;
        }

        .dot {
          &::before {
            border-color: $dot-grey;
            background: $dot-grey;
          }
          &.is-selected {
            &::before {
              background: $black;
              border-color: $black;
            }
          }
        }
      }
    }

    .product-image-thumbnails {
      width: 100%;

      .flickity-prev-next-button {
        display: none;
      }

      .carousel-item {
        $thumb-list-design-width: 636px;
        $thumb-margin-right: 11px;
        $thumb-width: ($thumb-list-design-width - ($thumb-margin-right * 4)) / 5;
        margin-top: percentage(19px / $thumb-list-design-width);
        margin-right: percentage($thumb-margin-right / $thumb-list-design-width);
        width: percentage($thumb-width / $thumb-list-design-width);

        @include phone {
          display: none;
        }

        .image {
          @include force-square-image;
        }
      }
    }
  }

  .product-options {
    .cms-product-message {
      @include feijoa(18px, 16px);
      line-height: 1.22;
      margin: 1rem 0;
      padding-left: 2em;
      position: relative;

      &::before {
        @include feijoa-bold(18px, 16px);
        color: $white;
        background: $black;
        width: 1.44em;
        height: 1.44em;
        line-height: 1.44em;
        margin-right: .5rem;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        content: 'i';
        position: absolute;
        margin-left: -2em;
        top: 50%;
        transform: translateY(-50%);
      }

      &:empty {
        display: none;
      }
    }
    .header-inner {
      @include phone {
        border-top: 1px solid $black;
        padding-top: 1em;
      }

      &:nth-child(2) {
        @include desktop-and-tablet {
          padding: 2em 0 0;
        }
      }
    }

    .option-anchor {
      a, input, select {
        @include clear-anchor-style;
        @include raleway-bold(16px);
        line-height: 20px;
        display: block;
        padding: 25px;
        letter-spacing: 2px;
        text-align: center;
        max-width: 100%;
        background: $white;
        color: $black;
        border: 1px solid $black;
        text-transform: uppercase;

        @include phone-portrait {
          margin-left: auto;
          margin-right: auto;
        }
      }

      select {
        width: 100%;
      }

      &.qty {
        margin-right: 1rem;
        .form-field.quantity {
          width: 140px;

          @include phone {
            width: 100px;
          }

          label {
            display: none;
          }

          input[type="number"] {
            border: 0;
          }
        }
      }

      &.add-cart {
        input {
          width: 220px;

          @include phone {
            width: 175px;
          }
        }
      }

      &.create-book {
        margin-top: 2rem;
        a {
          width: 380px;
        }
      }
    }

    .pricing {
      .variant-price {
        @include feijoa(24px, 20px);
      }
    }

    .cart-add {
      display: flex;
      flex-wrap: wrap;
      margin: 1rem 0;

      .option-anchor {
        &.product-options {
          display: flex;
          width: 100%;
          margin: 1rem 0;

          .form-field {
            margin-right: 2rem;

            label,
            .select-wrap,
            .input-wrap {
              display: block
            }

            label {
              @include feijoa(18px, 16px);
              line-height: 1.22;
              margin-bottom: 0.35rem;
            }

            .select-wrap::after {
              right: 8px;
            }
          }
        }

        &.qty {
          position: relative;

          input[type="number"] {
            -webkit-appearance: textfield;
            -moz-appearance: textfield;
            appearance: textfield;
          }

          input[type=number]::-webkit-inner-spin-button,
          input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
          }

          button {
            outline:none;
            -webkit-appearance: none;
            background-color: transparent;
            border: none;
            align-items: center;
            justify-content: center;
            width: 2em;
            height: 2em;
            cursor: pointer;
            margin: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            &.minus {
              left: 1em;
              &:after {
                content: '-';
              }

              @include phone {
                left: .5em;
              }
            }

            &.plus {
              right: 1em;
              &:after {
                content: '+';
              }

              @include phone {
                right: .5em;
              }
            }
          }
        }
      }
    }

    .product-option {
      p {
        margin: 0;
      }

      .cms-product-message {
        margin-top: 1em;
      }

      .option-variant .variant-name,
      .option-description {
        @include raleway-regular(22px, 18px);
        line-height: 1.27;
      }

      .option-name,
      .option-variant .variant-price {
        @include raleway-bold(22px, 18px);
        line-height: 1.27;
      }

      .option-description {
        margin: 0 0 1em;
      }
    }
  }

  .product-about-section {
    $product-about-margin: 60px;
    $product-about-left: 322px;
    $product-about-right: $desktop-inner - $product-about-margin - $product-about-left;
    clear: both;

    .product-about-left,
    .product-about-right {
      @include desktop-and-tablet {
        display: inline-block;
      }
    }

    .product-about-left {
      @include desktop-and-tablet {
        float: left;
        width: percentage($product-about-left / $desktop-inner);
        margin-right: percentage($product-about-margin / $desktop-inner);
      }

      .key-points {
        .title {
          @include raleway-bold(22px);
          line-height: 1.42;
        }

        .points-list {
          .point-wrap {
            min-height: 3.3em;
            margin-bottom: 24px;
            clear: both;

            .icon {
              width: 48px;
              height: 48px;
              border-radius: 50%;
              background-position: center;
              background-repeat: no-repeat;
              background-size: 100%;
              display: inline-block;
              margin-right: 1em;
              float: left;
            }
            span {
              @include raleway-regular(18px);
              line-height: 1.33;
              vertical-align: top;
              padding-left: calc(48px + 1em);
              display: block;
            }
          }
        }
      }
    }

    .product-about-right {
      position: relative;

      @include desktop-and-tablet {
        width: percentage($product-about-right / $desktop-inner);
      }
    }

    $about-logo-width: 215px;
    .product-about-intro {
      margin-bottom: 3rem;

      .section-heading {
        @include phone {
          width: 100%;
          padding-right: 100px;
          margin-bottom: 1.5rem;
          max-width: none;
        }
      }

      .intro-text,
      .about-logo {
        @include desktop-and-tablet {
          display: inline-block;
        }
      }

      .intro-text {
        @include feijoa(24px, 20px);
        line-height: 1.25;
        position: relative;

        @include desktop-and-tablet {
          width: percentage(($product-about-right - $about-logo-width) / $product-about-right);
          float: left;
          padding-top: ($m6 / 24px) * 1em;
          padding-right: 1em;
        }

        @include phone {
          margin-top: $m3;
        }
      }

      .about-logo {
        @include desktop-and-tablet {
          width: percentage($about-logo-width / $product-about-right);
        }

        @include phone {
          position: absolute;
          right: 0;
          top: -$m3;
          width: 100px;
          transform: translateY(-100%);
        }
      }
    }

    $info-block-width: 390px;
    $info-block-margin-width: 24px;

    .product-key-points {
      position: relative;

      @include phone {
        display: flex;
        flex-direction: column;

        .product-about-left {
          order: 2;
        }

        .product-about-right {
          order: 1;
        }
      }

      .background {
        @include decoration-dot('dot_lightgreen.svg');
        right: 0;
        top: percentage(($dot-design-width * 4) / $desktop-inner);
      }

      .product-info-blocks {
        display: flex;

        @include phone {
          display: block;
        }

        .info {
          padding: 2em;
          border-radius: 18px;
          width: percentage($info-block-width / $product-about-right);

          @include desktop-and-tablet {
            min-height: 25vw;
          }

          @include phone {
            width: 100%;
            margin-bottom: 2em;
          }

          &:first-child {
            background-color: $light-red;
            margin-right: percentage($info-block-margin-width / $product-about-right);
          }

          &:last-child {
            background-color: $blue;
            position: relative;
            display: flex;
            flex-direction: column;

            .description {
              margin-top: 0;
            }

            &::after {
              $width: percentage(51px / $info-block-width);
              $aspect-ratio: 115.55px / 51px;
              @include decoration($width, $aspect-ratio, 'slinkyman2.svg',
                                  $max-size: 51px);
              position: initial;
              margin-left: auto;
              margin-top: auto;
              z-index: 1;

              @include phone {
                $width: 51px;
                @include decoration($width, $aspect-ratio, 'slinkyman2.svg');
                position: initial;
                z-index: 1;
              }
            }

            .description {
              @include phone {
                margin-bottom: 8em;
              }
            }
          }

          .title,
          .description {
            line-height: 1.33;
          }

          .title {
            @include feijoa-bold(18px);
            margin-top: 0;
          }

          .description {
            @include feijoa(18px);
          }
        }
      }
    }

    .about-image {
      margin: 6rem 0;

      @include desktop-and-tablet {
        @include feijoa(24px, 20px);
        width: percentage(($desktop-inner - $about-logo-width) / $desktop-inner);
        padding-right: 1em;
      }
    }
  }

  .product-inaction-section {
    .section-heading {
      margin-bottom: 1em;
    }

    .product-media {
      position: relative;
      display: flex;

      @include desktop-and-tablet {
        margin-bottom: percentage($m3 / $desktop-inner);
      }

      @include phone {
        display: block;
      }

      > .background {
        @include decoration-dot('dot_pink.svg');
        left: percentage(390px / $desktop-inner);
        top: 0;
        margin-top: percentage((-$dot-design-width * 3) / $desktop-inner);
      }

      .media-wrap {
        position: relative;

        @include desktop-and-tablet {
          width: 50% - percentage(15px / $desktop-inner);
        }

        &:last-child {
          @include desktop-and-tablet {
            margin-left: percentage(30px / $desktop-inner);
          }

          #animated-rainbow-costume {
            $width: percentage(152px / 570px);
            @include decoration-animation($width);
            margin-top: percentage(-91.5px / 375px);
            margin-right: percentage(152px / 570px);
            top: 0;
            right: 0;

            @include phone {
              display: none;
            }
          }
        }

        @include phone {
          width: 100%;
          margin-right: 0;
        }

        .image {
          border-radius: 18px;
        }

        p {
          @include raleway-medium(25px, 21px);
          @include phone {
            margin: 1em 0 1.5em;
          }
        }
      }
    }
  }

  .section-heading {
    $section-heading-font-size: 67px;
    @include raleway-bold($section-heading-font-size, 40px);
    position: relative;
    line-height: .9;
    padding-top: ($m6 / $section-heading-font-size) * 1em;
    padding-bottom: .1em;
    width: (475px / $section-heading-font-size) * 1em;
    max-width: 100%;
    margin: 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: (80px / $section-heading-font-size) * 1em;
      $border-size: (5px / $section-heading-font-size) * 1em;
      border-top: $border-size solid;

      @include phone {
        border-width: 4px;
      }
    }
  }

  .scrapbook-section {
    position: relative;

    @include desktop-and-tablet {
      margin-bottom: percentage($m3 / $desktop-inner);
    }

    @include phone {
      margin-bottom: $m4;
    }

    .background-3 {
      @include decoration-dot('dot_yellow.svg');
      right: percentage((($desktop-inner - $new-design-width) / 2) / $desktop-inner);
      margin-right: percentage(($dot-design-width * 2) / $desktop-inner);
      top: 0;
    }

    .section-heading {
      width: percentage(537px / $desktop-inner);
      margin-bottom: 1em;

      @include phone {
        width: 100%;
      }
    }
  }

  .usage-tips-section {
    margin-bottom: percentage(60px / $desktop-inner);
  }

  .reviews-section {
    $section-heading-font-size: 67px;
    position: relative;

    .background-1 {
      @include decoration-dot('dot_pink.svg');
      left: percentage(280px / $desktop-inner);
      top: percentage(($dot-design-width * 6) / $desktop-inner);
    }

    .background-2 {
      @include decoration-dot('dot_red.svg');
      right: percentage(260px / $desktop-inner);
      top: percentage(($dot-design-width * 7.5) / $desktop-inner);
    }

    .background-3 {
      @include responsive-font-size($section-heading-font-size, 40px);
      @include decoration-dot('dot_lightblue.svg');
      right: 0;
      top: 1em;
    }
  }

  .insitu.image {
    @include desktop-and-tablet {
      margin: percentage($m2 / $desktop-inner) 0 0;
    }

    @include phone {
      margin: 50px 0 0;
    }
  }

  .available-books-section {
    .section-heading {
      @include phone {
        margin-bottom: 1em;
      }
    }

    #animated-heart {
      top: 0;
      right: 0;
      position: absolute;
      $width: percentage(280px / $desktop-inner);
      @include decoration-animation($width);
      margin-right: percentage(160px / $desktop-inner);
      margin-top: -6em;

      @include phone {
        display: none;
      }
    }
  }

  .stamped-pagination {
    @include phone {
      // 3rd party lib has !important in their code...
      margin-top: 1em !important;
    }
  }
}
