@import '../type';
@import '../constants';

.main-footer {
  // Set here so we can line up content with header where necessary
  // (eg. subscribe)
  $section-heading-font-size: 40px;
  @include raleway-bold($section-heading-font-size);
  position: relative;
  z-index: $footer-zindex;

  html.no-js &,
  &.initialised {
    opacity: 1;
  }

  .footer-section-heading {
    position: relative;
    line-height: .9;
    padding-top: (20px / $section-heading-font-size) * 1em;
    margin: 0;

    @include desktop-and-tablet {
      width: 100%;
      padding-right: 50px;
      max-width: 360px;
    }

    @include phone {
      max-width: 260px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: (80px / 67px) * 1em;
      $border-size: (5px / $section-heading-font-size) * 1em;
      border-top: $border-size solid;

      @include phone {
        border-width: 4px;
      }
    }
  }

  .instagram-section {
    position: relative;
    background: $yellow;
    text-align: center;

    $heading-design-outer-height: 92px;
    $instagram-desktop-padding-top: $m2;
    $instagram-phone-padding-top: 35px;

    .footer-section-heading {
      text-align: left;
    }

    .instagram-inner {
      position: relative;

      @include desktop-and-tablet-landscape {
        padding: percentage($instagram-desktop-padding-top / $design-max) percentage($desktop-margin / $design-max) percentage($m5 / $design-max);
      }

      @include tablet-portrait-and-phone-landscape {
        padding: percentage(65px / $tablet-portrait) percentage($tablet-margin / $tablet-portrait) 50px;
      }

      @include phone-portrait {
        padding: $instagram-phone-padding-top percentage($phone-margin / $phone-design-width) 50px;
      }
    }

    .instagram-feed {
      @include clearfix;
      position: relative;

      @include desktop-and-tablet-landscape {
        margin: percentage($m5 / $desktop-inner) 0;
      }

      @include tablet-portrait-and-phone {
        margin: $m5 0;
      }

      > .instagram.image {
        @include desktop-and-tablet {
          width: percentage(232px / $desktop-inner);
          float: left;
          margin-left: percentage(30px / $desktop-inner);

          &:first-child {
            margin-left: 0;
          }
        }

        @include phone {
          width: percentage(162px / $phone-inner);
          float: left;
          margin: 0 0 percentage(17px / $phone-inner) percentage(17px / $phone-inner);

          &:nth-child(2n+1) {
            margin-left: 0;
          }

          &:nth-child(5) {
            display: none;
          }
        }

        .caption {
          display: none;
        }
      }
    }

    a.follow {
      @include raleway-bold(17px);
      display: inline-block;
      padding: 1.45em;
      border: 1px solid $black;
      width: 100%;
      max-width: 250px;
      letter-spacing: .1em;
      text-transform: uppercase;
      background: $yellow;
      min-width: percentage(236px / $desktop-inner);

      @include hover {
        background-color: $black;
        color: $yellow;
      }
    }
  }

  .awards-and-features-section {
    @include clearfix;
    position: relative;

    @include desktop-and-tablet-landscape {
      padding: 0 percentage($desktop-margin / $design-max);
      margin: 0 0 percentage($m2 / $design-max);
    }

    @include tablet-portrait-and-phone-landscape {
      padding: 0 percentage($tablet-margin / $tablet-portrait);
      margin: 0 0 percentage($m2 / $tablet-portrait);
    }

    @include phone-portrait {
      padding: 0 percentage($phone-margin / $phone-design-width);
      margin: 0 0 percentage($m2 / $phone-design-width);
    }

    .section-heading {
      margin-bottom: 1em;
    }

    .awards-and-features-inner {
      @include desktop-and-tablet {
        padding-top: (20px / $section-heading-font-size) * 1em;
      }

      @include phone {
        padding-top: $m5;
      }
    }
  }

  .subscribe-section {
    @include clearfix;
    position: relative;

    @include desktop-and-tablet-landscape {
      margin: percentage($m3 / $design-max) percentage($desktop-margin / $design-max);
    }

    @include tablet-portrait-and-phone-landscape {
      margin: percentage($m3 / $tablet-portrait) percentage($tablet-margin / $tablet-portrait);
    }

    @include phone-portrait {
      margin: percentage($m3 / $phone-design-width) percentage($phone-margin / $phone-design-width);
    }

    .footer-section-heading {
      margin: percentage($m2 / $desktop-inner) auto percentage($m5 / $desktop-inner);
      padding: 0;
      text-align: center;
      &::before {
        border: 0;
      }
    }

    .subscribe-inner {
      @include clearfix;
      width: 100%;
      text-align: center;

      @include phone {
        padding-top: $m5;
      }
    }

    .cms-text {
      @include feijoa-medium(21px, 16px);

      @include phone {
        margin-bottom: 40px;
      }
    }

    .subscribe-form {
      @include desktop-and-tablet {
        margin-top: 3em;
      }
      .single-line-wrapper {
        @include raleway-semi-bold(19px);
        padding-right: 5em;

        @include desktop-and-tablet {
          width: percentage(690px / $desktop-inner);
        }

        .form-field.email_address {
          width: 100%;

          .email-wrap {
            max-width: none;
            width: inherit;

            input {
              line-height: 1.66;
              letter-spacing: 1px;
              text-align: left;
              padding: 1.625em;
            }
          }
        }
      }

      input[type='submit'] {
        width: 5em;
        background-color: $white;
        // Invisible text rather than no text, for screen readers
        color: transparent;
        background-image: url('../../../../i/rocket.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 45%;

        @include hover {
          opacity: $hover-opacity;
        }
      }

      .message {
        margin: .5em 0 0;
      }
    }
  }

  .footer-nav {
    @include clearfix;
    position: relative;
    background: $black;
    color: $white;
    text-align: center;

    &::before {
      $aspect-ratio: 91px / 156px;
      bottom: 0;
      right: 0;

      @include desktop-and-tablet {
        $width: percentage(156px / $design-max);
        @include decoration($width, $aspect-ratio, 'rainface1l.svg');
        margin-right: percentage(100px / $design-max);
        z-index: 1;
      }

      @include phone {
        $width: percentage(75px / $phone-design-width);
        @include decoration($width, $aspect-ratio, 'rainface1l.svg');
        margin-right: percentage($phone-margin / $phone-design-width);
        z-index: 1;
      }
    }

    .footer-nav-inner {
      @include desktop-and-tablet-landscape {
        padding: percentage($m3 / $design-max) percentage($desktop-margin / $design-max)
                 percentage($m2 / $design-max);
      }

      @include tablet-portrait-and-phone-landscape {
        padding: percentage($m4 / $tablet-portrait)
                 percentage($tablet-margin / $tablet-portrait)
                 percentage($m3 / $tablet-portrait);
      }

      @include phone-portrait {
        padding: percentage($m5 / $phone-design-width)
                 percentage($phone-margin / $phone-design-width)
                 percentage($m2 / $phone-design-width);
      }
    }

    a {
      @include inverse-anchor-style;
    }

    .social-nav {
      @include clearfix;
      $icon-width: 35px;
      $margin: $m6;
      width: $icon-width * 2 + $margin;
      margin: 0;

      @include desktop-and-tablet {
        float: left;
      }

      @include phone {
        margin: 0 auto percentage($m5 / $phone-inner);
      }

      li {
        $font-size: 14px;
        @include raleway-bold($font-size);
        float: left;
        margin: 0 0 0 $margin;
        color: $tan;

        &:first-child {
          margin-left: 0;
        }

        &.instagram {
          a {
            background-image: url('../../../../i/social/instagram_white.svg');
          }
        }

        &.facebook {
          a {
            background-image: url('../../../../i/social/facebook_white.svg');
          }
        }

        a {
          @include opacity-anchor-style;
          display: block;
          width: $icon-width;
          height: 0;
          padding-top: 36px / 35px * $icon-width;
          color: transparent;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }

    .shop-policies {
      $font-size: 18px;
      @include feijoa-medium($font-size, 14px);

      @include desktop-and-tablet {
        @include clearfix;
        display: inline-block;
        margin: 0 0 percentage($m4 / $desktop-inner);
        padding-right: 90px;
      }

      li {
        @include desktop-and-tablet {
          float: left;
          padding: 0 ($m6 / $font-size * 1em);
          border-left: 1px solid;

          &:first-child {
            border-left: 0;
          }
        }

        @include phone {
          margin: 0 0 $m6;
        }

        a {
          @include inverse-anchor-style;
        }
      }
    }

    .legal {
      @include feijoa-medium(12.5px, 10px);
      font-size: .7em;

      @include phone {
        margin-top: $m5;
      }

      p {
        margin: 0 0 .75em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
