@import 'c/main/src/admin_constants';
@import 'c/main/src/constants';
@import 'c/main/src/type';
@import 'c/main/lib/mixins';

body.change-form.model-personalisedorder {
  .form-row.field-name.field-email.field-phone.field-customer,
  .form-row.field-created_at_str.field-updated_at_str.field-closed_at_str.field-cancelled_at_str,
  .form-row.field-received_by_website_at_str.field-went_to_printer_at_str.field-printed_at_str.field-shipped_at_str.field-printing_cancelled_at_str,
  .form-row.field-financial_status.field-fulfillment_status,
  .form-row.field-total_price.field-total_presentment_price,
  .form-row.field-total_shipping.field-total_presentment_shipping,
  .form-row.field-currency.field-presentment_currency,
  .form-row.field-note.field-reference.field-tags_str,
  .form-row.field-price.field-presentment_price
  .form-row.field-discounted_price.field-discounted_presentment_price {
    > .fieldBox {
      @media (max-width: $admin-tablet-max) {
        @include clearfix;
      }

      @media (min-width: #{$admin-tablet-max + 1}) {
        width: percentage(1 / 3);
      }

      @media (min-width: #{$admin-tablet-max + 1}) {
        margin-right: 0;
        padding-right: 20px;
        box-sizing: border-box;
      }
    }
  }

  // .form-row.field-name.field-email.field-phone.field-customer,
  // .form-row.field-created_at.field-updated_at.field-closed_at.field-cancelled_at {
  //   > .fieldBox {
  //     @media (min-width: #{$admin-tablet-max + 1}) {
  //       width: 25%;
  //     }
  //   }
  // }
  //
  // .form-row.field-received_by_website_at.field-went_to_printer_at.field-printed_at.field-shipped_at.field-printing_cancelled_at {
  //   > .fieldBox {
  //     @media (min-width: #{$admin-tablet-max + 1}) {
  //       width: 20%;
  //     }
  //   }
  // }
  //
  // .form-row.field-financial_status.field-fulfillment_status,
  // .form-row.field-total_price.field-total_presentment_price,
  // .form-row.field-total_shipping.field-total_presentment_shipping,
  // .form-row.field-currency.field-presentment_currency {
  //   > .fieldBox {
  //     @media (min-width: #{$admin-tablet-max + 1}) {
  //       width: 50%;
  //     }
  //   }
  // }
  //
  // .form-row.field-note.field-reference.field-tags_str {
  //   > .fieldBox {
  //     @media (min-width: #{$admin-tablet-max + 1}) {
  //       width: percentage(1 / 3);
  //     }
  //   }
  // }
}

.order-download-page {
  .section-heading {
    $section-heading-font-size: 67px;
    @include raleway-bold($section-heading-font-size, 38px);
    position: relative;
    line-height: .9;
    padding-top: ($m6 / $section-heading-font-size) * 1em;
    width: (475px / $section-heading-font-size) * 1em;
    max-width: 100%;
    margin: 0 0 percentage($m3 / $desktop-inner);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: (80px / $section-heading-font-size) * 1em;
      $border-size: (5px / $section-heading-font-size) * 1em;
      border-top: $border-size solid;
    }
  }

  .intro {
    @include feijoa-medium(24px, 14px);
    p {
      line-height: 1.4;
    }
  }

  .intro {
    margin: 0 0 percentage($m3 / $desktop-inner);
    max-width: 600px;
  }

  table {
    margin: 0 0 percentage($m3 / $desktop-inner);
    border-collapse: collapse;

    tr {
      border-top: 1px solid $grey;
    }

    th,
    td {
      text-align: left;
      padding: $m5 $m6 $m5 0;

      span {
        @include raleway-medium;
        font-size: .9em;
      }

      a {
        @include raleway-bold;
        position: relative;
        display: inline-block;
        padding: 15px 50px;
        border: 1px solid $black;
        letter-spacing: .05em;
        text-transform: uppercase;
        background-color: $black;
        color: $tan;

        @include hover {
          background-color: $tan;
          color: $black;
        }
      }

      .image {
        @include desktop-and-tablet-landscape {
          float: left;
          width: 150px;
          margin-right: $m6;
        }

        @include tablet-portrait {
          width: 150px;
          margin-bottom: $m6;
        }

        @include phone {
          width: 115px;
          margin-bottom: $m6;
        }
      }
    }

    th {
      @include raleway-semi-bold(18px, 14px);
      vertical-align: top;

      @include desktop-and-tablet-landscape {
        width: 495px;
      }
    }

    td {
      @include raleway-medium(18px, 14px);
      width: 275px;

      @include desktop-and-tablet-landscape {
        vertical-align: top;
      }

      @include tablet-portrait-and-phone {
        vertical-align: bottom;
      }
    }
  }
}
