//
// Font-faces
//

@mixin webfont($name, $filename, $weight: 400, $style: normal) {
  // sass-lint:disable-all
  // see https://css-tricks.com/snippets/css/using-font-face/
  @font-face {
    font-family: "#{$name}";
    src: url('./' + $filename + '.eot');
    src: url('./' + $filename + '.eot?#iefix') format('embedded-opentype'),
         url('./' + $filename + '.woff2') format('woff2'),
         url('./' + $filename + '.woff') format('woff'),
         url('./' + $filename + '.ttf') format('truetype'),
         url('./' + $filename + '.svg') format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

@mixin klim-webfont($name, $filename, $weight: 400, $style: normal) {
  // sass-lint:disable-all
  // see https://css-tricks.com/snippets/css/using-font-face/
  @font-face {
    font-family: "#{$name}";
    src: url('./' + $filename + '.eot');
    src: url('./' + $filename + '.eot?#iefix') format('embedded-opentype'),
         url('./' + $filename + '.woff2') format('woff2'),
         url('./' + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
  }
}

@mixin printfont($name, $filename, $weight: 400, $style: normal) {
  @font-face {
    font-family: "#{$name}";
    src: url('./' + $filename + '.eot');
    src: url('./' + $filename + '.eot?#iefix') format('embedded-opentype'),
         url('./' + $filename + '.otf') format('opentype'),
         url('./' + $filename + '.woff2') format('woff2'),
         url('./' + $filename + '.woff') format('woff'),
         url('./' + $filename + '.ttf') format('truetype'),
         url('./' + $filename + '.svg') format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

@include printfont('Plantin Infant MT Std', 'PlantinInfantMTStd');

@include webfont('raleway', 'raleway-black-webfont', 900);
@include webfont('raleway', 'raleway-blackitalic-webfont', 900, italic);
@include webfont('raleway', 'raleway-extrabold-webfont', 800);
@include webfont('raleway', 'raleway-extrabolditalic-webfont', 800, italic);
@include webfont('raleway', 'raleway-bold-webfont', 700);
@include webfont('raleway', 'raleway-bolditalic-webfont', 700, italic);
@include webfont('raleway', 'raleway-semibold-webfont', 600);
@include webfont('raleway', 'raleway-semibolditalic-webfont', 600, italic);
@include webfont('raleway', 'raleway-medium-webfont', 500);
@include webfont('raleway', 'raleway-mediumitalic-webfont', 500, italic);
@include webfont('raleway', 'raleway-regular-webfont', 400);
@include webfont('raleway', 'raleway-italic-webfont', 400, italic);
@include webfont('raleway', 'raleway-light-webfont', 300);
@include webfont('raleway', 'raleway-lightitalic-webfont', 300, italic);
@include webfont('raleway', 'raleway-extralight-webfont', 200);
@include webfont('raleway', 'raleway-extralightitalic-webfont', 200, italic);
@include webfont('raleway', 'raleway-thin-webfont', 100);
@include webfont('raleway', 'raleway-thinitalic-webfont', 100, italic);

@include klim-webfont('feijoa', 'FeijoaWeb-Bold', 700);
@include klim-webfont('feijoa', 'FeijoaWeb-Medium', 500);
@include klim-webfont('feijoa', 'FeijoaWeb-MediumItalic', 500, italic);
@include klim-webfont('feijoa-display', 'FeijoaWeb-Display', 500);
