@import 'c/main/lib/mixins';
@import 'c/main/src/constants';
@import 'c/main/src/type';
@import './books/mixins';

$nav-width: 345px;
$panel-shadow-width: 1px;
$panel-shadow-fade: 5px;
// We use this varaible with calc to avoid seeing the box-shadow when the menu
// is closed.
$shadow-adjust: $panel-shadow-width + $panel-shadow-fade;

.main-nav {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  transition: transform $transition-time;
  transform: translateX(-100%);
  will-change: transform;
  z-index: $nav-zindex;

  @include desktop-and-tablet-landscape {
    // sass-lint:disable no-duplicate-properties
    width: $nav-width;
    width: calc(#{$nav-width} + #{$shadow-adjust});
  }

  @include tablet-portrait {
    $width: percentage(1 / 2);
    width: $width;
    width: calc(#{$width} + #{$shadow-adjust});
  }

  @include phone {
    $width: 100%;
    width: $width;
    width: calc(#{$width} + #{$shadow-adjust});
    max-width: 375px + $shadow-adjust;
  }

  body.menu-primary &,
  body.menu-secondary & {
    transform: translateX(0);
  }

  &::after {
    // screen
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    // avoid subpixel issues
    margin-left: calc(-#{$shadow-adjust + 1px});
    width: 100vw;
    background-color: transparentize($tan, 0.1);
    visibility: hidden;
    opacity: 0;
    z-index: 1;
    transition: opacity $transition-time, visibility $transition-time;
  }

  body.menu-primary &,
  body.menu-secondary & {
    &::after {
      opacity: 1;
      visibility: inherit;
    }
  }

  html.no-js &,
  .main-menu.initialised & {
    display: block;
  }

  .primary-panel .go-home,
  .primary-panel .toggle-primary-menu,
  .secondary-panel .toggle-secondary-menu {
    margin: percentage(40px / $nav-width);

    @include hover {
      opacity: $hover-opacity;
    }
  }

  .primary-panel,
  .secondary-panel {
    @include scrollable;
  }

  .primary-panel .toggle-primary-menu,
  .secondary-panel .toggle-secondary-menu {
    cursor: pointer;
    float: right;

    .hamburger {
      @include desktop-and-tablet {
        @include hamburger(38px, 49px, 38px, 3px, 6px, $active-selector: 'div &');
      }

      @include phone {
        @include hamburger(38px, 49px, 24px, 2px, 5px, $active-selector: 'div &');
      }
    }
  }

  .primary-panel {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    background: $menu-light-grey;
    color: $black;
    z-index: 3;
    box-shadow: $panel-shadow-width 0px $panel-shadow-fade 0px rgba(0,0,0,0.75);
    // Avoid seeing box-shadow when closed
    padding-left: calc(#{$shadow-adjust});
    margin-left: calc(-#{$shadow-adjust});

    .go-home {
      $aspect-ratio: 136px / 106px;
      $width: percentage(38px / $nav-width);
      @include opacity-anchor-style;
      float: left;
      width: 0;
      padding-left: $width;
      height: 0;
      padding-top: $width * $aspect-ratio;
      background-image: url('../../../../i/home.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .primary-link-list {
      @include raleway-bold(30px);
      float: left;
      width: 100%;
      clear: both;

      @include desktop-and-tablet {
        margin-top: percentage(120px / $nav-width);
      }

      @include phone {
        margin-top: percentage(20px / $nav-width);
      }

      li {
        color: $black;

        &.journal {
          background-image: url('../../../../i/blog-news.svg');
          background-repeat: no-repeat;
          background-position: center right .15em;
          background-size: 2.6em;
        }
      }

      a {
        display: block;
        padding: percentage(18px / $nav-width) percentage(18px / $nav-width)
                 percentage(18px / $nav-width) percentage(41px/ $nav-width);

        @include hover {
          opacity: .6;
        }
      }

      $colors: $yellow, $light-blue, $orange, $dark-green, $light-purple, $white, $pink;
      @for $i from 1 through length($colors) {
        li:nth-child(#{$i}) {
          background-color: nth($colors, $i);
        }
      }
    }

    .secondary-link-list {
      @include clearfix;
      margin: percentage(40px / $nav-width) 0;
      clear: both;

      li {
        @include raleway-bold(14px);
        margin-top: 1.5em;
        padding-left: percentage(45px / $nav-width);
        color: $tan;

        &:first-child {
          margin-top: 0;
        }

        &.social {
          float: left;
          margin-top: percentage(40px / $nav-width);

          &.instagram {
            a {
              background-image: url('../../../../i/social/instagram_black.svg');
            }
          }

          &.facebook {
            a {
              background-image: url('../../../../i/social/facebook_black.svg');
            }
          }

          + .social {
            padding-left: percentage(25px / $nav-width);
          }

          a {
            display: block;
            width: 35px;
            height: 0;
            padding-top: 36px;
            color: transparent;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }

        a {
          @include opacity-anchor-style;
        }
      }
    }
  }

  .secondary-panel {
    $secondary-panel-padding: 40px;
    $secondary-panel-inner: $nav-width - ($secondary-panel-padding * 2);
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: $white;
    transition: transform $transition-time;
    transform: translateX(-100%);
    will-change: transform;
    box-shadow: $panel-shadow-width 0px $panel-shadow-fade 0px rgba(0,0,0,0.75);
    // Avoid seeing box-shadow when closed and subpixel issues
    padding-left: calc(#{$shadow-adjust});
    // margin-left: see below

    @include desktop-and-tablet {
      left: 100%;
      z-index: 2;
      margin-left: calc(-#{($shadow-adjust * 2) + 1px});
    }

    @include phone {
      left: 0;
      z-index: 3;
      margin-left: calc(-#{$shadow-adjust + 1px});
    }

    body.menu-secondary & {
      transform: translateX(0);
    }

    .toggle-secondary-menu {
      @include desktop-and-tablet {
        display: none;
      }

      @include phone {
        display: inline-block;
      }
    }

    .books-nav-list {
      float: left;
      width: 100%;
      clear: both;
      padding: 0 percentage($secondary-panel-padding / $nav-width);

      @include desktop-and-tablet {
        margin: percentage($secondary-panel-padding / $nav-width) 0;
      }

      @include phone {
        margin: percentage(40px / $nav-width) 0 percentage($secondary-panel-padding / $nav-width);
      }

      .book-menu-list-item {
        @include standard-list-product($secondary-panel-padding, $secondary-panel-inner);
        margin-top: percentage($secondary-panel-padding / $secondary-panel-inner);

        a {
          display: block;

          @include hover {
            .text p {
              color: transparentize($black, 1 - $hover-opacity);
            }
          }

          &.current-page {
            .text p {
              color: transparentize($black, 1 - $hover-opacity);
            }

            &.related-hovered {
              .text p {
                color: $black;
              }
            }
          }
        }

        .image {
          @include force-square-image;
        }

        .book-launch-date {
          margin-top: 1em;
        }

        .hover-image {
          display: none;
        }
      }
    }
  }
}
