@import '../../constants';
@import '../../type';

// Everything within this form needs to be sized such that:
// 1. All subforms are the same height; and
// 2. The creation process (visible subform plus the footer nav) always fits
//    within the viewport.
// All child elements are therefore sized based on vh, including font-size,
// with the vh value based on the height of the element in the design. We use
// a custom function for convenience.
// The design height is based on a viewport 1000px high less the height of the
// header (generally 71px) and is broken into step height: 770px, footer height: 109px,
// and margin top and bottom of 25px (margin top is implemented as a
// padding-top on sub-forms so anchoring to them leaves a bit of whitespace at
// the top).
$design-height: 1000px;

@function vh-property($px-value) {
  @return $px-value / $design-height * 100vh;
}

@function vw-property($px-value) {
  @return $px-value / $design-max * 100vw;
}

@mixin footer-breakpoint {
  @media screen and (max-aspect-ratio: 1000 / 875) {
    @content;
  }
}

@mixin footer-resize-breakpoint {
  @media screen and (max-aspect-ratio: 668 / 985) {
    @content;
  }
}

.book-creation-process {
  @include clearfix;
  position: relative;
  text-align: center;

  &.show-footer {
    .previous-step:not([value='']) {
      visibility: inherit;
      opacity: 1;
    }

    .next-step,
    .book-creation-form-footer {
      visibility: inherit;
      opacity: 1;
    }
  }

  .swappable.image {
    margin: 0 auto vh-property(20px);

    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: vh-property(60px);
      height: 0;
      padding-top: vh-property(60px);
      background-image: url('../../../../../i/loader.gif');
      background-repeat: no-repeat;
      background-size: 100%;
      opacity: 0;
      visibility: hidden;
    }

    &.loading {
      &::before {
        opacity: 1;
        visibility: inherit;
      }

      img {
        opacity: 0;
      }
    }

    img {
      transition: opacity $transition-time;
      opacity: 1;
    }
  }

  .step-heading {
    text-align: center;
    line-height: 1;
    padding-bottom: .1em;
  }

  .form-field {
    text-align: center;

    .errorlist {
      @include raleway-bold;
      font-size: vh-property(14px);
      display: block;
      color: $bright-orange;
      text-transform: uppercase;
      letter-spacing: .025em;

      li {
        float: none;
      }
    }
  }

  .carousel-select {
    .option-item {
      // Avoid Flickity subpixel handling resulting in borders sometimes
      // being cut off due to flickity-viewport overflow: hidden.
      padding: 2px;
    }
  }

  .book-creation-form {
    margin: 0 auto;
    padding: vh-property(25px) vh-property(25px) 0;
    // A book page is 19.99cm x 25.07cm, and the preview section has two of
    // them side by side so 39.98cm x 25.07cm = 0.627063531765883 aspect
    // We want a preview height of 800, so a max-width of 1276px.
    // We set this here rather than on the preview sub-form so that .next-step
    // lines up with the edge of the preview/
    // We always base this on the side by side (2 page) preview, as this
    // max-width won't cause issues for any of the other sub-forms. When we
    // switch to the single page preview this is taken care of in
    // .preview.sub-form.
    max-width: vh-property(1276px);

    // we also leave room for fixed header in browsers that support calc
    $header-height: (($header-top-margin + $header-bottom-margin +
      $main-heading-design-size) / $main-heading-design-size) * 1em;
    max-width: calc(#{vh-property(1276px)} - #{$header-height});

    @include desktop-tablet-and-phone-landscape {
      @include responsive-font-size(
        $shrunk-heading-design-size, $main-heading-phone-start-size);
    }

    @include phone-portrait {
      @include responsive-font-size(
        $main-heading-phone-start-size, $main-heading-phone-end-size,
        $start-shrinking-at: $phone-portrait);
    }

    &.valid {
      .next-step {
        background-color: $bright-green;
        cursor: pointer;

        @include hover {
          border: 1px solid;
          color: $bright-green;
          background-color: $tan;
        }
      }
    }
  }

  .sub-forms-wrapper {
    @include clearfix;  // fallback
    display: flex;
    align-items: center;
  }

  .sub-form {
    // Stack them up but without position absolute so the footer never jumps
    // around, even if the heights aren't consistent.
    float: left;
    margin-right: -100%;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity $transition-time, visibility $transition-time;

    // only assigned when adding .current, in which case we add a delay
    // rather than crossfading.
    &.transition {
      transition: opacity $transition-time $transition-time,
                  visibility $transition-time $transition-time;
    }

    &.current {
      visibility: inherit;
      opacity: 1;
    }

    &.name-form {
      // This one is a bit more complex because Tim wants the heading large,
      // but it's quite long so would break across an extra line on mobile
      // devices. For devices below a certain aspect-ratio, we therefore bump
      // down the font-size so it doesn't break, and add extra margin top so
      // height still matches.
      // Heading: 50px * 2 (2 lines) + 50px * .1 (padding) + 10px (margin-bottom) = 157.5px
      // Heading: 47px * 2 (2 lines) + 47px * .1 (padding) + 10px (margin-bottom) + 49px (margin-top) = 157.7px
      // Image: 449px + 20px = 469px
      // Label: 27px + (27px * .5) (margin) = 40.5px
      // Input: 40px * 1.1 (1 line) + (40px * .7 * 2) = 104px
      // Total: 771px

      .create-intro-heading {
        @include raleway-bold;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        font-size: vh-property(70px);
        margin: 0 0 vh-property(10px);

        @media screen and (max-aspect-ratio: 700 / 900) {
          font-size: vh-property(47px);
          margin-top: vh-property(49px);
        }

        span {
          display: block;
          width: 100%;

          &:last-child {
            color: $light-green;
          }
        }
      }

      .swappable.image {
        // 925 / 550 * 449
        max-width: vh-property(755px);  // (height of 449px)
      }

      .form-field.name {
        margin: 0 auto;

        > label {
          @include raleway-bold;
          font-size: vh-property(27px);
          display: block;
          line-height: 1;
          margin: 0 0 .5em;
        }

        .text-wrap {
          @include raleway-bold;
          font-size: vh-property(40px);
          border: 0;
          max-width: vh-property(570px);
        }

        input[type='text'] {
          @include autofill($white, $black, 2.5em);
          background: $white;
          padding: .7em 2em;
          text-align: center;

          @include phone {
            padding: .7em 1em;
          }

          &:invalid {
            text-align: left;
          }

          @include placeholder {
            @include raleway-medium;
            font-size: .5em;
            line-height: 2.2em;
            text-align: center;
          }
        }
      }
    }

    &.avatar-form {
      // Two modes:

      // Heading: 50px * 2 (lines) + 50px * .1 (padding) + 20px = 125px
      // Image: 428px + 20px = 448px
      // Label: 26px (1 line) + 19px (margin) = 45px
      // Gender inputs: 20px
      // Avatar inputs: 113px (image) + 19px (margin) = 132px
      // Total: 770px

      // Heading: 46px * 2 (lines) + 46px * .1 (padding) + 20px = 114.5px
      // Image: 428px + 20px = 448px
      // Label: 26px (1 line) + 19px (margin) = 45px
      // Gender inputs: 27px
      // Avatar inputs: 113px (image) + 19px (margin) = 132px
      // Total: 769px

      $gender-font-resizepoint: 605px;

      .avatar-heading {
        @include raleway-bold;
        font-size: vh-property(50px);
        color: $bright-orange;
        margin: 0 0 vh-property(20px);

        @media screen and (max-height: $gender-font-resizepoint) {
          font-size: vh-property(46px);
        }

        .break {
          display: block;
        }
      }

      .avatar-image-wrap {
        @include clearfix;
        // 925 / 550 * 428px
        max-width: vh-property(720px);  // (height of 428px)
        margin: 0 auto vh-property(20px);

        .perry.image,
        .avatar.image {
          float: left;
          width: 50%;
          margin: 0;
        }

        .perry.image {
          img {
            right: 0;
            margin-left: auto;
          }
        }
      }

      .form-field.gender,
      .form-field.avatar {
        > label {
          @include raleway-bold;
          font-size: vh-property(26px);
          display: block;
          line-height: 1;
          margin: 0 0 vh-property(19px);
        }
      }

      .form-field.avatar {
        margin: 0 auto;

        $option-item-width: 125px;
        $option-item-margin: 15px;

        $default-width: $option-item-width * 3 + $option-item-margin * 2;
        $default-vh-width: vh-property($default-width);
        max-width: $default-vh-width;

        // sass-lint:disable no-duplicate-properties
        @include desktop {
          $default-width: $option-item-width * 6 + $option-item-margin * 5;
          $default-vh-width: vh-property($default-width);
          max-width: $default-vh-width;
          max-width: calc(#{$default-vh-width} + 60px);

          .option-item {
            width: percentage($option-item-width / $default-width);
            margin-right: percentage($option-item-margin / $default-width);
          }
        }

        @include tablet-landscape {
          $default-width: $option-item-width * 5 + $option-item-margin * 4;
          $default-vh-width: vh-property($default-width);
          max-width: $default-vh-width;
          max-width: calc(#{$default-vh-width} + 60px);

          .option-item {
            width: percentage($option-item-width / $default-width);
            margin-right: percentage($option-item-margin / $default-width);
          }
        }

        @include tablet-portrait-and-phone-landscape {
          $default-width: $option-item-width * 4 + $option-item-margin * 3;
          $default-vh-width: vh-property($default-width);
          max-width: $default-vh-width;
          max-width: calc(#{$default-vh-width} + 44px);

          .option-item {
            width: percentage($option-item-width / $default-width);
            margin-right: percentage($option-item-margin / $default-width);
          }
        }

        @include phone-portrait {
          $default-width: $option-item-width * 3 + $option-item-margin * 2;
          $default-vh-width: vh-property($default-width);
          max-width: $default-vh-width;
          max-width: calc(#{$default-vh-width} + 30px);

          .option-item {
            width: percentage($option-item-width / $default-width);
            margin-right: percentage($option-item-margin / $default-width);
          }
        }

        .carousel {
          @include desktop-and-tablet-landscape {
            padding: 0 30px;
          }

          @include tablet-portrait-and-phone-landscape {
            padding: 0 22px;
          }

          @include phone-portrait {
            padding: 0 15px;
          }

          &.hidden {
            visibility: hidden;

            html.no-js & {
              visibility: inherit;
            }
          }

          .flickity-viewport {
            margin-bottom: 0;
          }

          .flickity-prev-next-button {
            @include tablet-portrait-and-phone-landscape {
              &.previous {
                left: percentage(-40px / $new-design-width);
              }

              &.next {
                right: percentage(-40px / $new-design-width);
              }
            }

            @include phone-portrait {
              &.previous {
                left: percentage(-80px / $new-design-width);
              }

              &.next {
                right: percentage(-80px / $new-design-width);
              }
            }
          }

          .flickity-page-dots {
            display: none;
          }
        }

        .carousel-item {
          &.hidden {
            display: none;

            html.no-js & {
              display: block;
            }
          }
        }

        .input-option-wrap {
          display: block;
          border: 1px solid;
          overflow: hidden;

          @include hover {
            background: $bright-orange;
            border-color: $bright-orange;
          }

          &.checked {
            background: $bright-orange;
            border-color: $bright-orange;

            &,
            label {
              cursor: default;
            }
          }

          .radio-wrap {
            // Make sure browser validation messages show up.
            width: 1px;
            height: 1px;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
          }
        }
      }

      .form-field.gender {
        max-width: vh-property(685px);
        margin: vh-property($m6) auto 0;
        text-align: center;

        #id_gender {
          display: inline-block;
          margin: 0;

          li {
            @include raleway-medium
            font-size: vh-property(18px);
            line-height: 1;
            margin-left: vh-property(30px);
            display: inline-block;

            @media screen and (max-height: $gender-font-resizepoint) {
              font-size: vh-property(25px);
            }

            @include phone {
              text-align: left;
              margin-bottom: .75em;
              margin-left: vh-property(10px);
            }

            &:first-child {
              margin-left: 0;
            }

            .input-option-wrap {
              @include hover {
                color: $bright-orange;

                .radio-wrap {
                  background-color: $bright-orange;
                }
              }

              &.checked {
                color: $bright-orange;

                &,
                label {
                  cursor: default;
                }

                .radio-wrap {
                  background-color: $bright-orange;
                }
              }
            }

            .radio-wrap {
              display: inline-block;
              width: 1em;
              height: 1em;
              border: 1px solid;
              border-radius: 50%;
              vertical-align: top;
            }

            label {
              padding-left: .65em;
            }
          }
        }
      }
    }

    &.book-type-form {
      // Heading: 50px * 2 (2 lines) + 50px * .1 (padding) = 105px
      // Image: 511.5px + 20px = 531.5px
      // Label: 27px (1 line) + 27px * 1.5 (margin) = 40.5px
      // Book input: 73px
      // Total: 770px

      .book-type-heading {
        @include raleway-bold;
        font-size: vh-property(50px);
        color: $bright-orange;
        max-width: vh-property(650px);
        margin: 0 auto;
      }

      .book-type.image {
        // 925 / 550 * 511.5
        max-width: vh-property(860px);  // (height of 511.5px)
      }

      .form-field.book {
        margin: 0 auto;

        $option-item-width: 220px;
        $option-item-margin: 58px;

        $default-width: $option-item-width * 2 + $option-item-margin * 1;
        $default-vh-width: vh-property($default-width);
        max-width: $default-vh-width;

        // sass-lint:disable no-duplicate-properties
        @include desktop-and-tablet-landscape {
          max-width: calc(#{$default-vh-width} + 60px);
        }

        @include tablet-portrait-and-phone-landscape {
          max-width: calc(#{$default-vh-width} + 44px);
        }

        @include phone-portrait {
          max-width: calc(#{$default-vh-width} + 30px);
        }

        .option-item {
          width: percentage($option-item-width / $default-width);
          margin-right: percentage($option-item-margin / $default-width);
        }

        @media screen and (min-aspect-ratio: 680 / 680) {
          $total-width: $option-item-width * 3 + $option-item-margin * 2;
          $total-vh-width: vh-property($total-width);
          max-width: $total-vh-width;

          // sass-lint:disable no-duplicate-properties
          @include desktop-and-tablet-landscape {
            max-width: calc(#{$total-vh-width} + 60px);
          }

          @include tablet-portrait-and-phone-landscape {
            max-width: calc(#{$total-vh-width} + 44px);
          }

          @include phone-portrait {
            max-width: calc(#{$total-vh-width} + 30px);
          }

          .option-item {
            width: percentage($option-item-width / $total-width);
            margin-right: percentage($option-item-margin / $total-width);
          }
        }

        > label {
          @include raleway-bold;
          font-size: vh-property(27px);
          display: block;
          line-height: 1;
          margin: 0 0 .5em;
        }

        .carousel {
          @include desktop-and-tablet-landscape {
            padding: 0 30px;
          }

          @include tablet-portrait-and-phone-landscape {
            padding: 0 22px;
          }

          @include phone-portrait {
            padding: 0 15px;
          }

          .flickity-viewport {
            margin-bottom: 0;
          }

          .flickity-page-dots {
            display: none;
          }
        }

        .input-option-wrap {
          display: block;

          @include hover {
            .title {
              background: $bright-orange;
              border-color: $bright-orange;
              color: $tan;
            }

            .subtitle {
              color: $bright-orange;
            }
          }

          &.checked {
            &,
            label {
              cursor: default;
            }

            .title {
              background: $bright-orange;
              border-color: $bright-orange;
              color: $tan;
            }

            .subtitle {
              color: $bright-orange;
            }
          }

          label,
          .title,
          .subtitle {
            display: block;
          }

          .title {
            @include raleway-semi-bold;
            font-size: vh-property(18px);
            padding: (12px / 18px * 1em) (30px / 18px * 1em);
            border: 1px solid;
            border-radius: 5px;
          }

          .subtitle {
            @include raleway-semi-bold;
            font-size: vh-property(15px);
            margin: 1em 0 0;
            letter-spacing: .025em;
          }
        }
      }
    }

    &.preview {
      position: relative;
      // Image: 770px
      // Total: 770px

      &.loading,
      &.initialising {
        &::before {
          content: '';
          position: absolute;
          display: block;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: vh-property(60px);
          height: 0;
          padding-top: vh-property(60px);
          background-image: url('../../../../../i/loader.gif');
          background-repeat: no-repeat;
          background-size: 100%;
          z-index: 2;
        }
      }

      .book-preview-carousel {
        margin: 0 auto;
        // 39.98cm / 25.07cm * 770px
        max-width: vh-property(1220px);  // (height of 770px)

        // For portrait screens we switch to a single page preview
        @media screen and (max-aspect-ratio: 1/1) {
          // 19.99cm / 25.07cm * 770px
          max-width: vh-property(614px);  // (height of 770px)
        }

        &.interacted {
          .flickity-prev-next-button.next {
            &::before,
            &::after {
              display: none;
            }
          }
        }

        .rendered-book-page {
          @media screen and (min-aspect-ratio: 1/1) {
            width: 50%;
          }

          @media screen and (max-aspect-ratio: 1/1) {
            width: 100%;
          }

          &.intro {
            min-height: 100%;

            @media screen and (min-aspect-ratio: 1/1) {
              width: calc(50% - 10px);
              display: flex;
              align-items: center;

              p {
                @include raleway-bold(40px, 20px);
                margin: 0 20%;
                text-align: center;
              }

              + .rendered-book-page {
                width: calc(50% + 10px);
                padding-left: 10px;
              }
            }

            @media screen and (max-aspect-ratio: 1/1) {
              display: none;
            }
          }

          &:nth-child(2n+1) {
            @media screen and (min-aspect-ratio: 1/1) {
              padding: 10px 0 10px 10px;

              .book-page-image {
                border-radius: 2px 10px 10px 2px;

                &::after {
                  background: linear-gradient(to left, black 0%, rgba(0, 0, 0, 0.1) 25%, rgba(0, 0, 0, 0) 100%);
                  right: 0;
                }
              }
            }
          }

          &:nth-child(2n+2) {
            @media screen and (min-aspect-ratio: 1/1) {
              padding: 10px 10px 10px 0;

              .book-page-image {
                border-radius: 10px 2px 2px 10px;

                &::after {
                  background: linear-gradient(to right, black 0%, rgba(0, 0, 0, 0.1) 25%, rgba(0, 0, 0, 0) 100%);
                  left: 0;
                }
              }
            }
          }

          .book-page-image {
            overflow: hidden;
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);

            &::after {
              @media screen and (min-aspect-ratio: 1/1) {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                height: 100%;
                width: 100px;
                opacity: .2;
                pointer-events: none;
              }
            }
          }

          .textblock-wrapper {
            @media screen and (min-aspect-ratio: 1/1) {
              html.no-js & {
                font-size: 25vw !important;
              }
            }
          }
        }

        .flickity-prev-next-button {
          background: $bright-orange;

          @include hover {
            background: $bright-orange;

            .flickity-button-icon {
              opacity: $hover-opacity;
            }
          }

          &:active {
            opacity: 1;
            background: $bright-orange;

            .flickity-button-icon {
              opacity: $hover-opacity;
            }
          }

          &:disabled {
            visibility: hidden;
          }

          &.previous {
            @media screen and (min-aspect-ratio: 1/1) {
              margin-right: -40px;
            }
          }

          &.next {
            @media screen and (min-aspect-ratio: 1/1) {
              margin-left: -40px;
            }

            &::before {
              @include raleway-bold(16px);
              content: 'Preview';
              position: absolute;
              display: block;
              bottom: 100%;
              margin-bottom: 12px;
              right: 0;
              padding: 10px 15px;
              background: $black;
              color: $white;
            }

            &::after {
              content: '';
              position: absolute;
              display: block;
              bottom: 100%;
              margin-bottom: 3px;
              left: 50%;
              transform: translateX(-50%);
              border-top: 10px solid $black;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
            }
          }

          .flickity-button-icon {
            fill: $white;
          }
        }
      }
    }
  }

  .previous-step,
  .next-step {
    $font-size: 17px;
    @include raleway-bold;
    font-size: vh-property($font-size);
    text-transform: uppercase;
    min-width: vh-property(190px);
    border: 1px solid transparent;
    border-radius: 5px;
    padding: (20px / $font-size * 1em) (30px / $font-size * 1em);
    letter-spacing: .075em;
    opacity: 0;
    visibility: hidden;
    transition: opacity $transition-time, visibility $transition-time;
    background-color: $grey;
    color: $tan;
    cursor: default;

    @include phone {
      $font-size: 12px;
      font-size: vh-property($font-size);
      min-width: vh-property(160px);
    }

    html.no-js & {
      opacity: 1;
      visibility: inherit;
    }
  }

  .previous-step {
    float: left;
    margin: vh-property(30px) vh-property(50px) 0 0;
    background-color: $bright-green;
    cursor: pointer;

    @include footer-breakpoint {
      margin: vh-property(35px) 0 0;
    }

    @include hover {
      border: 1px solid;
      color: $bright-green;
      background-color: $tan;
    }
  }

  .next-step {
    float: right;
    margin: vh-property(30px) 0 0 vh-property(50px);

    @include footer-breakpoint {
      margin: vh-property(35px) 0 0;
    }

    html.no-js & {
      background-color: $bright-green;
      cursor: pointer;

      @include hover {
        border: 1px solid;
        color: $bright-green;
        background-color: $tan;
      }
    }
  }

  .book-creation-form-footer {
    max-width: vh-property(1000px);
    margin: 0 auto;
    padding: 0 0 vh-property(25px);

    @include footer-breakpoint {
      clear: both;
      padding: vh-property(35px) 0 vh-property(25px);
    }
  }
}

.book-creation-form-footer {
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity $transition-time, visibility $transition-time;

  html.no-js & {
    display: none;
  }

  .step {
    // sizing for idx, text is positioned absolutely so the lines between
    // steps are easier to line up.
    $step-size: 35px;
    $phone-margin: 35px;
    @include inline-block;
    @include raleway-bold;
    position: relative;
    text-transform: uppercase;
    color: $grey;
    font-size: vh-property(12px);
    margin-left: vh-property(90px);
    margin-top: vh-property(25px);
    // Leave room for text, assuming two lines
    margin-bottom: 4em;

    &:first-child {
      margin-left: 0;
    }

    @include footer-breakpoint {
      margin-top: 0;
    }

    @include footer-resize-breakpoint {
      font-size: vw-property(36px);
      margin-left: vw-property(150px);
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      border-top: 1px solid;
      left: vh-property(-90px);
      width: vh-property(90px);

      @include footer-resize-breakpoint {
        left: vw-property(-150px);
        width: vw-property(150px);
      }
    }

    &.completed {
      &,
      + .step {
        color: $bright-orange;
      }
    }

    &:first-child {
      color: $bright-orange;

      &::before {
        display: none;
      }
    }

    button {
      @include raleway-bold;
      width: 100%;
      text-transform: uppercase;
      color: inherit;
      font-size: inherit;

      &[type='submit'] {
        border: 0;
        padding: 0;
        overflow: visible;

        @include hover {
          &:not(:disabled) {
            .idx {
              background: $bright-orange;
              color: $tan;
              border-color: $bright-orange;
            }
          }
        }
      }
    }

    .idx {
      display: block;
      border: 1px solid;
      border-radius: 50%;
      width: vh-property($step-size);
      height: vh-property($step-size);
      line-height: vh-property($step-size) * .9;
      font-size: vh-property(15px);

      @include footer-resize-breakpoint {
        width: vw-property(115px);
        height: vw-property(115px);
        line-height: vw-property(115px) * .9;
        font-size: vw-property(50px);
      }
    }

    .text {
      line-height: 1.5;
      padding: 1em 0 0;
      letter-spacing: .075em;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
