@import '../constants';
@import '../type';

.reviews-section {
  .reviews-carousel {
    .carousel-review {
      width: 100%;
      text-align: center;

      .review-inner {
        width: 100%;

        @include desktop-and-tablet {
          $review-width: 847px;
          width: percentage($review-width / $desktop-inner);
          margin: 0 auto;

          .rating,
          .review-text {
            margin: 0 0 percentage($m6 / $review-width);
          }
        }

        .rating {
          @include raleway-bold(25px, 22px);

          .star {
            margin-right: 4px;

            &::before {
              display: inline-block;
              content: '\2605';
              color: $yellow;
            }
          }
        }

        .review-text {
          p {
            @include feijoa-medium(20px, 18px);
            line-height: 1.3;
          }
        }

        .reviewer-name,
        .reviewer-bio {
          @include feijoa(18px);
          line-height: 1.11;
          font-style: italic;
          margin: 0;
        }

        .reviewer-bio {
          margin-bottom: 3em;
        }
      }
    }

    &.standard-carousel .flickity-prev-next-button {
      background: $white;
    }
  }
}
