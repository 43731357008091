@import 'c/main/src/constants';
@import 'c/main/src/type';

.about-page,
.books-about-page {
  position: relative;

  header {
    position: relative;
  }

  .page-hero {
    @include desktop-and-tablet-landscape {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 4;
      pointer-events: none;
    }
  }

  $section-heading-font-size: 67px;

  h1,
  .adhoc-block h2,
  .reviews-section h2,
  .available-books-section p {
    &.section-heading {
      @include raleway-bold($section-heading-font-size, 38px);
      position: relative;
      line-height: .9;
      padding-top: ($m6 / $section-heading-font-size) * 1em;
      width: (475px / $section-heading-font-size) * 1em;
      max-width: 100%;
      margin: 0 0 1em;
      text-align: left;

      @include phone {
        margin: 0 0 $m5;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: (80px / $section-heading-font-size) * 1em;
        $border-size: (5px / $section-heading-font-size) * 1em;
        border-top: $border-size solid;

        @include phone {
          border-width: 4px;
        }
      }
    }
  }

  h1.section-heading {
    margin: 0;
    z-index: 5;
    width: (360px / $section-heading-font-size) * 1em;

    @include desktop-and-tablet-landscape {
      padding-bottom: percentage(400px / $desktop-inner);
    }
  }

  .page-blocks {
    @include desktop-and-tablet {
      margin-top: percentage($m3 / $desktop-inner);
      margin-bottom: percentage($m2 / $desktop-inner);
    }

    @include phone {
      margin-top: 0;
      margin-bottom: percentage($m4 / $phone-inner);
    }

    @include tablet-portrait-and-phone {
      clear: both;
    }
  }

  .adhoc-block {
    position: relative;

    @include desktop-and-tablet {
      margin-bottom: percentage($m2 / $desktop-inner);
    }

    @include phone {
      margin-bottom: percentage($m4 / $phone-inner);
    }

    h2 {
      @include feijoa-bold(30px, 20px);
      text-align: center;

      @include desktop-and-tablet {
        margin: 0 0 percentage($m4 / $desktop-inner);
      }

      @include phone {
        margin: $m5;
      }
    }

    .block-text {
      @include feijoa-medium(26px, 22px);
      text-align: center;

      @include phone {
        text-align: left;
      }

      p {
        line-height: 1.4;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &.storytime,
    &.experts,
    &.personalisation,
    &.references {
      .block-text {
        @include responsive-font-size($section-heading-font-size, 38px);
        padding-top: ($m6 / $section-heading-font-size) * $section-heading-font-size;
        p {
          @include feijoa-medium(26px, 22px);
        }
      }
    }
  }

  .scrapbook-section,
  .available-books-section {
    @include desktop-and-tablet {
      margin: 0 0 percentage($m2 / $desktop-inner);
    }

    @include phone {
      margin: 0 0 percentage($m4 / $phone-inner);
    }
  }

  .scrapbook-section {
    .section-heading {
      @include phone {
        margin-bottom: 1em;
      }
    }
  }
}
