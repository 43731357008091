@import 'c/main/src/constants';
@import 'c/main/src/type';

$journal-max-width: 1170px;

$journal-base-font-size: 17px;
$journal-min-font-size: 12px;

$journal-background-color: #E3EDE2;
$journal-heading-color: #006638;
$journal-index-color: #006638;
$journal-detail-color: #006638;

@function em-property($px-value, $parent-font-size: $journal-base-font-size) {
  @return ($px-value / $parent-font-size * 1em);
}

@mixin h3-style {
  @include raleway-medium;
  font-size: em-property(30px);
  margin: 1em 0 .5em;
  line-height: 1.3;
}

@mixin h6-style {
  @include raleway-regular;
  line-height: 1.3;
  margin: 0 0 1em;

  @include desktop-and-tablet {
    font-size: em-property(20px);
  }

  @include phone {
    font-size: em-property(24px);
  }
}

@mixin p-style {
  @include raleway-regular;

  line-height: 1.5;
  margin: 0 0 1em;

  @include desktop-and-tablet {
    font-size: em-property(17px);
  }

  @include phone {
    font-size: em-property(20px);
  }
}

.journal-page {
  @include responsive-font-size($journal-base-font-size, $journal-min-font-size);
  background: $journal-background-color;

  &::before {
    content: '';
    background: inherit;
    position: absolute;
    bottom: 100%;
    margin-bottom: -1px;
    height: 500px;
    left: 0;
    width: 100%;
  }

  .section-title {
    @include raleway-bold(98px, 43px);
    color: $journal-heading-color;
    text-align: center;

    @include desktop-and-tablet {
      margin: percentage($m3 / $desktop-inner) 0 percentage($m4 / $desktop-inner);
    }

    @include phone {
      margin: 0 0 percentage($m5 / $phone-inner);
    }

    a {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        display: block;
        background-image: url('../../../../i/blog_masthead.png');
        width: em-property(120px, 120px);
        height: em-property(138px, 120px);
        right: em-property(173px, 120px);
        bottom: em-property(63px, 120px);
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;

        @media screen and (max-width: 341px) {
          right: em-property(100px, 120px);
        }
      }
    }
  }

  .tags-nav {
    color: $journal-heading-color;
    margin-bottom: em-property(60px);

    @include desktop-and-tablet {
      max-width: $journal-max-width;
      margin-left: auto;
      margin-right: auto;
    }

    .tags-collapser {
      ul {
        padding-top: em-property(25px);
      }
    }

    &.collapsed {
      .tag-nav-toggle::after {
        transform: rotateX(0);
      }

      .tags-collapser {
        html.js & {
          // !important to override element level style
          max-height: 0 !important;
        }
      }
    }

    .tag-nav-toggle {
      @include raleway-bold(25px, 18px);
      display: inline-block;
      color: $journal-heading-color;
      cursor: pointer;

      @include hover {
        text-decoration: underline;
      }

      html.no-js & {
        cursor: default;
      }

      &::after {
        content: '';
        display: inline-block;
        margin-left: .75em;
        width: 1em;
        height: 0.55em;
        background-image: url('../../../../i/ico-select.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        transform: rotateX(180deg);

        html.no-js & {
          display: none;
        }
      }
    }

    .tags-collapser {
      max-width: 750px;
      max-height: 500px; // overridden with js
      transition: max-height $transition-time;
      overflow: hidden;

      ul {
        margin: 0;

        li {
          @include raleway-semi-bold;
          font-size: em-property(15px);
          display: inline-block;
          margin: 0 .5em 1em 0;

          @include phone {
            font-size: em-property(16.5px);
            line-height: 1.174;
          }

          a {
            display: block;
            padding: .5em 1.5em;
            background: $white;
            color: $black;

            &.current-page {
              background: $journal-heading-color;
              color: $white;

              &.related-hovered {
                color: $journal-heading-color;
                background: $white;
              }
            }

            @include hover {
              background: $journal-heading-color;
              color: $white;
            }
          }
        }
      }
    }
  }

  .blog-article,
  .list-article {
    h1 {
      @include raleway-medium;
      font-size: em-property(54px);
      margin: 1em 0 .5em;
      letter-spacing: -0.01em;
    }

    h2 {
      font-size: em-property(42px);
      margin: 1em 0 .5em;
      letter-spacing: -0.01em;
    }

    h3 {
      @include h3-style;
    }

    h4 {
      @include raleway-medium;
      font-size: em-property(27px);
      line-height: 1.3;
      margin: 1em 0 .5em;
    }

    h5 {
      @include raleway-medium;
      font-size: em-property(24px);
      line-height: 1.3;
      margin: 1em 0 .5em;
    }

    h6 {
      @include h6-style;
    }

    p {
      @include p-style;
    }

    blockquote {
      margin: 0;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        @include feijoa;
      }
    }
  }

  .blog-article {
    h1, h2 {
      color: $journal-heading-color;
    }

    h2 {
      @include raleway-medium;
    }
  }

  .list-article {
    display: block;

    @include hover {
      .list-article-title {
        span {
          @include underline;
        }
      }

      .summary {
        text-decoration: underline;
      }
    }

    &:nth-child(3n+1) {
      a {
        .journal-image {
          border-bottom-right-radius: 50%;
          border-bottom-left-radius: 50%;
        }

        @include hover {
          .journal-image {
            border-top-right-radius: percentage(40px / 360px);
            border-top-left-radius: percentage(40px / 360px);
          }
        }
      }
    }

    &:nth-child(3n+2) {
      a {
        .journal-image {
          border-top-right-radius: percentage(40px / 360px);
          border-top-left-radius: percentage(40px / 360px);
          border-bottom-right-radius: percentage(40px / 360px);
          border-bottom-left-radius: percentage(40px / 360px);
        }

        @include hover {
          .journal-image {
            border-bottom-right-radius: 50%;
            border-bottom-left-radius: 50%;
            border-top-right-radius: 50%;
            border-top-left-radius: 50%;
          }
        }
      }
    }

    &:nth-child(3n+3) {
      a {
        .journal-image {
          border-top-right-radius: 50%;
          border-top-left-radius: 50%;
        }

        @include hover {
          .journal-image {
            border-bottom-right-radius: percentage(40px / 360px);
            border-bottom-left-radius: percentage(40px / 360px);
          }
        }
      }
    }

    > a {
      display: block;

      .journal-image {
        width: 100%;
        display: block;
        overflow: hidden;
        position: relative;
        padding-top: 100%;
        img {
          display: block;
          width: auto;
          height: 100%;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    .list-article-title {
      @include raleway-bold(26px);
      line-height: 1.1;
      color: $black;
    }

    .button-link {
      @include button-link;
      @include raleway-bold(13px);

      span {
        color: $black;
      }

      @include hover {
        background: $black;
        span {
          color: $journal-background-color;
        }
      }
    }
  }
}

.journal-index {
  color: $journal-index-color;

  .tags-nav {
    .tag-nav-toggle,
    .tags-collapser {
      @include desktop-and-tablet {
        margin-left: percentage(15px / $journal-max-width);
        margin-right: percentage(15px / $journal-max-width);
      }
    }
  }

  .articles {
    @include desktop-and-tablet {
      @include clearfix;
      max-width: $journal-max-width;
      margin-left: auto;
      margin-right: auto;
    }

    @include phone-landscape {
      @include clearfix;
    }

    .list-article {
      @include desktop-and-tablet {
        float: left;
        width: percentage(360px / $journal-max-width);
        margin: 0 percentage(15px / $journal-max-width)
                percentage(100px / $journal-max-width);

        &:nth-child(3n+1) {
          clear: left;
        }
      }

      @include phone-landscape {
        $journal-inner: ($tablet-portrait - ($tablet-margin * 2)) / $tablet-portrait * $phone-max;
        width: percentage(310px / $journal-inner);
        margin: 0 0 percentage(100px / $journal-inner);

        &:nth-child(2n+1) {
          float: left;
          clear: both;
        }

        &:nth-child(2n+2) {
          float: right;
        }
      }

      @include phone-portrait {
        margin: 0 0 percentage(100px / $phone-inner);
      }

      .summary {
        &,
        blockquote {
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p {
            @include raleway-regular(17px);
            line-height: 1.1;
            color: $black;
          }
        }
      }
    }
  }
}

.journal-detail {
  color: $journal-detail-color;

  $journal-inner: ($tablet-portrait - ($tablet-margin * 2)) / $tablet-portrait * $phone-max;

  .detail-inner {
    margin-left: auto;
    margin-right: auto;

    @include desktop-and-tablet {
      @include clearfix;
      max-width: $journal-max-width;
      padding-bottom: percentage(100px / $journal-inner);
    }
  }

  .blog-article {
    @include desktop-and-tablet {
      float: left;
      width: percentage(700px / $journal-max-width);
    }

    .share {
      @include clearfix;

      a {
        font-size: 30px;
        float: left;
        display: block;
        width: 1em;
        height: 1em;
        letter-spacing: -.5em;
        color: transparent;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: em-property(5px, 24px);

        @include hover {
          opacity: $hover-opacity;
        }

        &.twitter {
          background-image: url('../../../../i/t.svg');
        }

        &.pinterest {
          background-image: url('../../../../i/p.svg');
        }

        &.facebook {
          background-image: url('../../../../i/f.svg');
        }

        &.email {
          background-image: url('../../../../i/e.svg');
        }
      }
    }

    h1 {
      @include raleway-bold();
      margin-top: 1em;

      @include phone {
        padding-right: 1em;
      }
    }

    .article-body {
      @include p-style;
      color: $black;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      ul,
      blockquote,
      p {
        a {
          @include default-anchor-style;
        }
      }
    }

    img {
      display: block;
      margin: percentage(80px / $journal-max-width) 0;
      max-width: 100%;
      height: auto;
    }
  }

  .article-nav {
    @include desktop-and-tablet {
      float: right;
      width: percentage(410px / $journal-max-width);
    }

    @include phone {
      margin-top: percentage(50px / $journal-inner);
      padding-bottom: percentage(100px / $journal-inner);
    }

    @include phone-landscape {
      @include clearfix;
    }

    h3 {
      @include raleway-bold(24px)
      line-height: 31.2px;
      margin-bottom: 0;
    }

    ol {
      @include raleway-regular(18px);
      color: $black;
      line-height: 30px;
      padding: 1em 1em 0;
      border-top: 1px solid $black;

      @include desktop-and-tablet {
        max-width: 300px;
      }

      li {
        margin-bottom: .5em;
      }
    }

    // .other-posts {
    //   @include raleway-semi-bold;
    //   font-size: em-property(24px);
    //   color: $journal-heading-color;
    //   max-width: 360px;
    //   margin: 0 auto em-property(25px);
    // }
  }

  .list-article {
    max-width: 360px;
    margin: 0 auto percentage(100px / 410px);

    @include phone-landscape {
      width: percentage(310px / $journal-inner);
      margin: 0 0 percentage(100px / $journal-inner);

      &:nth-child(2n+1) {
        float: left;
        clear: both;
      }

      &:nth-child(2n+2) {
        float: right;
      }
    }

    @include phone-portrait {
      margin: 0 0 percentage(100px / $phone-inner);
    }

    img {
      width: 100%;
    }

    .list-article-title {
      color: $journal-detail-color;
    }

    .summary {
      &,
      blockquote {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
          @include p-style;
        }
      }
    }
  }
}
