@import '../../constants';
@import '../../type';

.book-purchase-page {
  @include clearfix;
  display: flex;
  padding-top: 100px;

  @include desktop-and-tablet {
    flex-wrap: wrap;
  }

  @include phone {
    flex-direction: column;
    padding: 0;
  }

  .dedication-measurer {
    width: 500px;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    z-index: 20;
  }

  .hero-image-wrap {
    $image-outer: $desktop-inner * .4;
    $image-side-padding: 125px;
    $image-inner: $image-outer - ($image-side-padding * 2);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;

    @include desktop-and-tablet {
      width: 40%;
      float: left;
      margin-bottom: 50px;
    }

    @include phone {
      order: 2;
      background: $light-grey !important;
      padding-bottom: $m4;
    }

    .hero-image-inner {
      width: 100%;
    }

    .image {
      margin: 0 auto;
      width: 90%;

      @include phone {
        display: none;
      }
    }

    .preview-form {
      width: 100%;

      @include desktop-and-tablet {
        margin-top: $m6;
      }

      @include phone {
        padding-left: percentage($phone-margin / $phone-design-width);
        padding-right: percentage($phone-margin / $phone-design-width);
      }

      button[type='submit'] {
        display: block;
        width: percentage($image-inner / $image-outer);
        max-width: 320px;
        left: 0;
        margin-left: auto;
        right: 0;
        margin-right: auto;
        bottom: 0;
        color: $black;
        background: $light-grey;
        border-radius: 8px;
        text-transform: uppercase;
        letter-spacing: .075em;

        @include desktop-and-tablet {
          @include raleway-bold(16px);
          margin-bottom: percentage(($desktop-margin / 2) / $image-outer);
          border-color: $light-grey;

          @include hover {
            background: transparent;
            border-color: $black;
          }
        }

        @include phone {
          @include raleway-bold(14px);
          width: 100%;

          @include hover {
            background: $black;
            color: $light-grey;
            border-color: $black;
          }
        }
      }
    }
  }

  .purchase-form-wrap {
    $form-outer: $desktop-inner * .6;
    $form-side-padding: $m5;
    $form-inner: $form-outer - ($form-side-padding * 2);
    background: $light-grey;

    @include desktop-and-tablet {
      width: 60%;
      float: right;
      padding: percentage($m6 / $desktop-inner) percentage($form-side-padding / $desktop-inner);
      margin-bottom: 50px;
    }

    @include phone {
      order: 1;
      padding: 50px percentage($phone-margin / $phone-design-width) 0;
    }

    .book-purchase-form {
      &.valid {
        input[type='submit'] {
          background: $bright-green;
          border-color: $bright-green;
          cursor: pointer;

          @include hover {
            color: $bright-green;
            background: $light-grey;
          }
        }
      }

      .purchase-heading {
        @include raleway-bold(27px);
        color: $bright-orange;
        text-align: center;

        @include desktop-and-tablet {
          margin: 0 0 $m6;
        }

        @include phone {
          margin: 0 0 $m5;
        }
      }

      > .form-field {
        margin-bottom: 20px;

        > label {
          @include raleway-bold(13px);
          display: block;
          text-align: left;
          text-transform: uppercase;
          letter-spacing: .075em;
          margin: 0 0 1em;
        }
      }

      .help {
        @include raleway-bold(12px);
        margin: .5em 0 0;
        max-width: 550px;
        color: $bright-orange;
        visibility: hidden;

        &.visible {
          visibility: inherit;
        }

        html.no-js & {
          font-weight: 500;
          color: inherit;
          visibility: inherit;

          .current,
          .warning {
            display: none;
          }
        }
      }

      textarea {
        @include raleway-medium(14px);
        display: block;
        width: 100%;
        padding: 25px;
        border: 1px solid;
        border-radius: 8px;
        line-height: 1.5;
        resize: none;

        @include tablet {
          // Avoid zoom in on iOS
          html.pointerevents & {
            font-size: 16px;
          }
        }

        @include phone {
          font-size: 16px;
        }
      }

      .form-field.id {
        $li-desktop-width: 190px;
        $li-phone-width: 170px;
        $num-list-items: 3;
        $ul-max-width: $li-desktop-width * $num-list-items;

        ul {
          @include clearfix;
          display: flex;
          flex-wrap: wrap;
          margin: 0;

          @include desktop-and-tablet {
            max-width: $ul-max-width;
          }
        }

        li {
          float: left;

          @include desktop-and-tablet {
            $li-desktop-padding: 25px;
            width: percentage($li-desktop-width / $ul-max-width);
            padding-right: percentage($li-desktop-padding / $ul-max-width);
          }

          @include phone {
            $li-phone-padding: $phone-margin;
            width: percentage($li-phone-width / $phone-inner);
            padding: 0 percentage($li-phone-padding / $phone-inner)
                     percentage($li-phone-padding / $phone-inner) 0;
          }

          .input-option-wrap {
            $option-wrap-font-size: 13px;
            @include raleway-bold($option-wrap-font-size);
            display: block;
            position: relative;

            @include hover {
              color: $bright-orange;

              .radio-wrap {
                background-color: $bright-orange;
              }
            }

            &.checked {
              color: $bright-orange;

              &,
              label {
                cursor: default;
              }

              .radio-wrap {
                background-color: $bright-orange;
              }
            }

            label {
              display: block;

              .image {
                display: block;
                width: 100%;
                max-width: 120px;

                .aspect-maintainer {
                  display: block;
                }
              }

              .format-title {
                display: block;
                margin: 1em 0;
                padding-left: 30px;
                text-transform: uppercase;
                text-align: left;
                color: $bright-orange;
                letter-spacing: .075em;
                width: 100%;
                line-height: 1.2;
                min-height: 2.4em;
                // intentionally no clearfix so we can position checkbox
                // correctly
                float: left;
              }

              .format-description {
                @include raleway-medium(12px);
                display: block;
                // intentionally no clearfix so we can position checkbox
                // correctly
                float: left;

                .ebook,
                .description {
                  display: block;
                }

                .ebook {
                  @include raleway-semi-bold;
                  text-transform: uppercase;

                  + .description {
                    margin-top: 1em;
                  }
                }
              }
            }

            .radio-wrap {
              position: absolute;
              top: 100%;
              left: 0;
              display: inline-block;
              width: 15px / $option-wrap-font-size * 1em;
              height: 15px / $option-wrap-font-size * 1em;
              border: 1px solid;
              border-radius: 50%;
              margin-top: 1em;
              vertical-align: top;
            }
          }
        }
      }

      .form-field.correct_checkbox {
        $correct-checkbox-font-size: 13px;
        @include raleway-bold($correct-checkbox-font-size);
        position: relative;

        @include desktop-and-tablet {
          margin: percentage($m5 / $form-inner) 0 0;
        }

        @include phone {
          margin: $m5 0 0;
        }

        .checkbox-wrap {
          position: absolute;
          left: 0;
          top: 0;
          width: 15px / $correct-checkbox-font-size * 1em;
          height: 15px / $correct-checkbox-font-size * 1em;
          border: 1px solid;
          border-radius: 50%;
          vertical-align: top;

          &.checked {
            background-color: $bright-orange;

            &,
            + label {
              color: $bright-orange;
            }
          }
        }

        label {
          display: inline-block;
          color: $bright-orange;
          line-height: 15px / $correct-checkbox-font-size;
          padding-left: 2.5em;
          margin: 0;
        }
      }

      input[type='submit'] {
        @include raleway-bold(14px);
        display: block;
        width: 320px;
        max-width: 100%;
        color: $light-grey;
        border-radius: 8px;
        text-transform: uppercase;
        letter-spacing: .075em;
        text-align: center;
        background: $grey;
        border-color: $grey;
        cursor: default;

        @include desktop-and-tablet-landscape {
          margin: percentage($m6 / $form-inner) auto 0;
        }

        @include tablet-portrait-and-phone {
          margin: $m5 auto 0;
        }
      }
    }

    .save-book {
      @include raleway-bold(14px);
      margin: 0 0 1em;
      line-height: 1.1;
      text-transform: uppercase;
      letter-spacing: .075em;
      text-align: center;

      @include phone {
        margin-bottom: 10px;
      }

      html.no-js & {
        display: none;
      }

      a {
        cursor: pointer;

        @include phone {
          display: block;
          width: 320px;
          max-width: 100%;
          margin: 0 auto;
          padding: 1em;
          border: 1px solid;
          background: $light-grey;
          border-radius: 8px;
        }

        @include hover {
          @include desktop-and-tablet {
            @include underline;
          }

          @include phone {
            color: $light-grey;
            background: $black;
          }
        }
      }
    }

    .save-message {
      margin: 10px 0;
      @include raleway-medium(10px);
      text-align: center;
    }
  }

  .book-creation-form-footer {
    $phone-margin: 35px;
    clear: both;
    width: 100%;
    opacity: 1;
    visibility: inherit;
    margin-bottom: 150px;

    @include phone {
      order: 3;
      margin-top: $phone-margin;
    }

    html.no-js & {
      margin-top: 100px;
    }
  }
}
