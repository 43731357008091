@import 'c/main/lib/mixins';

//
// _constants.scss
//
// Site specific variables, mixins and generic mixins which rely on site
// specific settings.
//

$body-font-max: 18px;
$body-font-min: 16px;

// largest 32-bit integer
$overlay-zindex: 2147483647;
$disabled-scroll-zindex: $overlay-zindex - 1;
$nav-zindex: 20;
$header-inner-zindex: $nav-zindex - 1;
$header-background-shape-zindex: $header-inner-zindex - 1;
$header-background-color-zindex: $header-background-shape-zindex - 1;
$footer-zindex: $header-background-color-zindex - 1;
$standard-page-zindex: $footer-zindex - 1;


$hover-opacity: .7;

$transition-time: 350ms;

$dot-design-width: 37px;

//
// Colors
//
$black: #10141D;
$white: #fff;
$off-white: #F0F1F2;
$light-grey: #F8F7EF;
$menu-light-grey: #E9E9E9;
$dot-grey: #C4C4C4;
$grey: #939396;
$tan: #F4E7D8;
$red: #AD3A30;
$light-red: #FA8D71;
$dark-red: #FF5D39;
$pink: #FFAAB7;
$orange: #ED732A;
$bright-orange: #FE5232;
$yellow: #FFBE46;
$blue: #779AB8;
$light-blue: #73C6F2;
$dark-green: #48AA5E;
$bright-green: #23C25D;
$light-green: #64A363;
$purple: #673D68;
$light-purple: #9484B3;

//
// Decorations etc
//
@mixin decoration($width, $aspect-ratio, $filename, $max-size: $width) {
  content: '';
  display: block;
  position: absolute;
  // Don't use width, because for absolutely positioned elements Safari
  // calculates width based on the offset parent's outerwidth (ie. ignores
  // margins), but calculates padding based on the the inner width. This causes
  // our width and height calculations to not match up.
  width: 0;
  padding-left: $width;
  height: 0;
  padding-top: $width * $aspect-ratio;
  background-image: url('../../../i/decoration/#{$filename}');
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: -1;
  pointer-events: none;

  @include above-design-max {
    padding-left: $max-size;
    padding-top: $max-size * $aspect-ratio;
  }
}

@mixin decoration-dot($filename, $width: $dot-design-width, $container: $desktop-inner,
                      $aspect-ratio: 1, $max-size: $dot-design-width) {
  $width: percentage($width / $container);
  content: '';
  display: block;
  position: absolute;
  // Don't use width, because for absolutely positioned elements Safari
  // calculates width based on the offset parent's outerwidth (ie. ignores
  // margins), but calculates padding based on the the inner width. This causes
  // our width and height calculations to not match up.
  width: 0;
  padding-left: $width;
  height: 0;
  padding-top: $width * $aspect-ratio;
  background-image: url('../../../i/decoration/#{$filename}');
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: -1;
  pointer-events: none;

  @include above-design-max {
    padding-left: $max-size;
    padding-top: $max-size * $aspect-ratio;
  }

  @include tablet-portrait-and-phone {
    display: none;
  }
}

@mixin decoration-animation($width) {
  position: absolute;
  width: $width;
  z-index: -1;
  pointer-events: none;
}

@mixin background($width, $aspect-ratio, $filename) {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  padding-left: $width;
  height: 0;
  padding-top: $width * $aspect-ratio;
  background-image: url('../../../i/backgrounds/#{$filename}');
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: -2;
  pointer-events: none;
}

@mixin button-link() {
  @include raleway-bold(16px);
  text-align: center;
  text-transform: uppercase;
  line-height: 1.25;
  letter-spacing: 2px;
  border: 1px solid $black;
  padding: 1.5em;
  @include tablet-portrait-and-phone {
    font-size: 14px;
    padding: 1em;
  }
}

@mixin force-square-image() {
  .aspect-maintainer {
    padding-top: 100% !important;
    img {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}


//
// Responsive settings.
//
// Throughout this project, mobile is used to refer to any mobile device
// including a tablet. Tablet is used specifially to refer to a tablet sized
// device (whether it's a actually a mobile device or not). Phone is used to
// refer specifically to a phone sized device.
$tablet-max: getSharedVariable(TABLET_MAX) * 1px;
$desktop-min: $tablet-max + 1px;
$tablet-portrait: getSharedVariable(TABLET_PORTRAIT) * 1px;
$tablet-landscape-min: $tablet-portrait + 1px;
$phone-max: getSharedVariable(PHONE_MAX) * 1px;
$tablet-min: $phone-max + 1px;
$phone-portrait: getSharedVariable(PHONE_PORTRAIT) * 1px;
$phone-design-width: getSharedVariable(PHONE_DESIGN_WIDTH) * 1px;
$phone-landscape-min: $phone-portrait + 1px;

//
// Structural
//
$design-max: getSharedVariable(DESIGN_MAX) * 1px;
$max-width: getSharedVariable(MAX_WIDTH);
@if ($max-width) {
  $max-width: $max-width * 1px;
}

$header-top-margin: 29px;
$header-bottom-margin: 22px;
$main-heading-design-size: 34px;
$shrunk-heading-design-size: 22px;
$main-heading-phone-start-size: 19px;
$main-heading-phone-end-size: 14px;

// Standard margins
$m1: 220px;
$m2: 120px;
$m3: 80px;
$m4: 60px;
$m5: 40px;
$m6: 20px;

$desktop-margin: 60px;
$desktop-inner: $design-max - $desktop-margin * 2;

$tablet-margin: 40px;
$tablet-inner: $tablet-portrait - $tablet-margin * 2;

$phone-margin: 17px;
$phone-inner: $phone-design-width - $phone-margin * 2;

$new-design-width: 1042px;

// These mixins provide convenience methods for styles that should be applied
// at certain viewport sizes.

@mixin above-design-max {
  html.no-mediaqueries & {
    @content;
  }

  @media screen and (min-width: #{$design-max + 1}) {
    @content;
  }
}

@mixin desktop {
  html.no-mediaqueries & {
    @content;
  }

  @media screen and (min-width: $desktop-min) {
    @content;
  }
}

@mixin desktop-and-tablet-landscape {
  html.no-mediaqueries & {
    @content;
  }

  @media screen and (min-width: $tablet-landscape-min) {
    @content;
  }
}

@mixin desktop-and-tablet {
  html.no-mediaqueries & {
    @content;
  }

  @media screen and (min-width: $tablet-min) {
    @content;
  }
}

@mixin desktop-tablet-and-phone-landscape {
  html.no-mediaqueries & {
    @content;
  }

  @media screen and (min-width: $phone-landscape-min) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: $tablet-max) and (min-width: $tablet-min) {
    @content;
  }
}

@mixin tablet-and-phone {
  @media screen and (max-width: $tablet-max) {
    @content;
  }
}

@mixin tablet-landscape {
  @media screen and (max-width: $tablet-max) and (min-width: $tablet-landscape-min) {
    @content;
  }
}

@mixin tablet-portrait {
  @media screen and (max-width: $tablet-portrait) and (min-width: $tablet-min) {
    @content;
  }
}

@mixin tablet-portrait-and-phone-landscape {
  @media screen and (max-width: $tablet-portrait) and (min-width: $phone-landscape-min) {
    @content;
  }
}

@mixin tablet-portrait-and-phone {
  @media screen and (max-width: $tablet-portrait) {
    @content;
  }
}

@mixin phone {
  @media screen and (max-width: $phone-max) {
    @content;
  }
}

@mixin phone-landscape {
  @media screen and (max-width: $phone-max) and (min-width: $phone-landscape-min) {
    @content;
  }
}

@mixin phone-portrait {
  @media screen and (max-width: $phone-portrait) {
    @content;
  }
}


//
// Anchor styles
//

@mixin clear-anchor-style {
  background-image: none;
  text-decoration: none;

  .cssgradients &,
  .no-cssgradients &,
  .no-js & {
    background-image: none;
    text-decoration: none;
  }

  &:hover,
  &.hover {
    .no-touchevents &,
    .cssgradients &,
    .no-cssgradients &,
    .no-js & {
      background-image: none;
      text-decoration: none;
    }
  }

  &.current-page {
    .cssgradients &,
    .no-cssgradients &,
    .no-js & {
      background-image: none;
      text-decoration: none;
    }

    &.related-hovered {
      .cssgradients &,
      .no-cssgradients &,
      .no-js & {
        background-image: none;
        text-decoration: none;
      }
    }
  }
}

// By default:
// 1. Links are underlined, except for links to the current-page (see
//    anchors.js);
// 2. The underline is removed on hover;
// 3. Links to the current-page are underlined if a related link (see
//    anchors.js) is hovered.
@mixin default-anchor-style($color: currentColor, $weight: 1px, $offset: 1px) {
  .no-cssgradients &,
  .no-js & {
    text-decoration: underline;
  }

  .cssgradients & {
    @include underline($color: $color, $weight: $weight, $offset: $offset);
  }

  &:hover {
    .no-touchevents & {
      background-image: none;
    }

    .no-cssgradients &,
    .no-js & {
      text-decoration: none;
    }
  }

  &.hover {
    .cssgradients & {
      background-image: none;
    }

    .no-cssgradients &,
    .no-js & {
      text-decoration: none;
    }
  }

  &.current-page {
    .cssgradients & {
      background-image: none;
    }

    .no-cssgradients &,
    .no-js & {
      text-decoration: none;
    }

    &.related-hovered {
      .no-cssgradients &,
      .no-js & {
        text-decoration: underline;
      }

      .cssgradients & {
        @include underline($color: $color, $weight: $weight, $offset: $offset);
      }
    }
  }
}

// The inverse style, typically used for headers and navs:
// 1. Links are not underlined, except for links to the current-page (see
//    anchors.js);
// 2. An underline is added on hover;
// 3. The underline on the link to the current-page is removed if a related
//    link (see anchors.js) is hovered.
@mixin inverse-anchor-style($color: currentColor, $weight: 1px, $offset: 1px) {
  .cssgradients & {
    background-image: none;
  }

  .no-cssgradients &,
  .no-js & {
    text-decoration: none;
  }

  &:hover {
    .no-touchevents & {
      @include underline($color: $color, $weight: $weight, $offset: $offset);
    }

    .no-cssgradients &,
    .no-js & {
      text-decoration: underline;
    }
  }

  &.hover {
    .cssgradients & {
      @include underline($color: $color, $weight: $weight, $offset: $offset);
    }

    .no-cssgradients &,
    .no-js & {
      text-decoration: underline;
    }
  }

  &.current-page,
  &.checked {
    .cssgradients & {
      @include underline($color: $color, $weight: $weight, $offset: $offset);

      &.related-hovered {
        background-image: none;
      }
    }

    .no-cssgradients &,
    .no-js & {
      text-decoration: underline;

      &.related-hovered {
        text-decoration: none;
      }
    }
  }
}

@mixin opacity-anchor-style {
  @include hover {
    opacity: $hover-opacity;
  }

  &.current-page {
    opacity: $hover-opacity;

    &.related-hovered {
      opacity: 1;
    }
  }
}

@mixin color-anchor-style($color) {
  @include hover {
    color: $color;
  }

  &.current-page {
    color: $color;

    &.related-hovered {
      color: inherit;
    }
  }
}
