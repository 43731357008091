@import '../../constants';
@import '../../type';
@import './mixins';

.book-detail-page {
  .product-detail-header {
    .product-tagline {
      max-width: 350px;

      @include phone {
        max-width: 250px;
      }
    }
  }

  .book-creation-section {
    @include phone {
      padding-left: percentage($phone-margin / $phone-design-width);
      padding-right: percentage($phone-margin / $phone-design-width);
    }
  }

  $cms-inner: 450px - 20px;
  $header-inner: 310px;
  $hero-width: 680px;

  .book-creation-section {
    @include phone {
      margin-top: percentage(-$m5 / $phone-inner);
    }
  }

  .product-about-section {
    // must match variables in products.scss
    $book-about-margin: 60px;
    $book-about-left: 322px;
    $book-about-right: $desktop-inner - $book-about-margin - $book-about-left;
    $info-block-width: 390px;
    $info-block-margin-width: 24px;
    
    .book-availability {
      position: relative;

      @include desktop-and-tablet {
        margin-top: percentage($m6 / $desktop-inner);
        margin-bottom: percentage($m2 / $desktop-inner);
        clear: both;
      }

      > .background {
        @include decoration-dot('dot_yellow.svg');
        bottom: 0;
        left: 0;
        margin-bottom: percentage((-$dot-design-width * 2) / $desktop-inner);
      }

      .product-about-left {
        p {
          @include raleway-bold(22px);
          line-height: 1.18;
          @include phone {
            width: 60%;
            margin-bottom: 2em;
          }
        }
      }

      .product-about-right {
        position: relative;
        margin-top: 1em;

        @include phone {
          display: block;
        }

        > .background {
          @include decoration-dot('dot_red.svg', $container: $book-about-right);
          bottom: 0;
          left: percentage((-$dot-design-width * 5) / $book-about-right);
        }

        > li {
          list-style: none;
          margin-bottom: 1em;
          width: percentage($info-block-width / $book-about-right);
          display: inline-block;

          @include desktop-and-tablet {
            padding-left: 2em;
            float: left;

            &:nth-of-type(2n + 1) {
              margin-right: percentage($info-block-margin-width / $book-about-right);
            }

            &:nth-of-type(3) {
              clear: left;
            }

            &:nth-of-type(4) {
              float: none;
            }
          }

          @include phone {
            padding: 0;
            width: 100%;
          }
        }

        p,
        li {
          line-height: 24px;
        }

        p {
          &.name {
            @include feijoa-bold(18px);
            margin: 0;
            @include phone {
              @include raleway-bold(18px);
            }
          }
        }

        .detailed-description {
          padding-left: 1.2em;
          margin: 0;
          li {
            @include feijoa(18px);
            line-height: 1.33;
            list-style-type: disc;
            @include phone {
              @include raleway-regular(18px);
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  .book-creation-section {
    $container-width: $desktop-inner * .75;
    position: relative;

    @include desktop-and-tablet {
      width: percentage(990px / $desktop-inner);
      margin-left: auto;
      margin-right: auto;
    }

    @include phone {
      margin-bottom: $m6;
    }

    .background-1 {
      @include decoration-dot('dot_darkgreen.svg', $container: $container-width);
      left: percentage((-$dot-design-width * 3) / $container-width);
      top: percentage($dot-design-width / $container-width);
    }

    .background-2 {
      @include decoration-dot('dot_lightblue.svg', $container: $container-width);
      bottom: percentage($dot-design-width / $container-width);
      right: percentage((-$dot-design-width * 4) / $container-width);
    }

    .section-anchor {
      @include phone {
        margin-top: percentage(-$m2 / $phone-inner);
      }
    }

    .book-creation-process {
      @include desktop-and-tablet {
        margin-bottom: percentage($m2 / $desktop-inner);
      }

      .book-creation-form-footer {
        @include phone {
          padding: 3.5vh 0;
        }
      }

      .sub-form {
        .text-wrap {
          @include phone {
            width: 90%;
          }
        }
      }

      .next-step {
        @include phone {
          margin-right: 1em;
        }
      }

      .step-heading {
        @include raleway-bold(67px, 55px);
        @include phone {
          @include raleway-bold(24px);
          margin-top: 0;
        }

        span:nth-child(2) {
          @include raleway-bold(49px, 55px);
          line-height: 1.17;
          margin: percentage($m6 / $desktop-inner) 0 0;
          @include phone {
            @include raleway-bold(18px);
          }
        }
      }
    }
  }
}
