@import 'c/main/lib/mixins';
@import 'c/main/src/constants';
@import 'c/main/src/type';

form {
  .errorlist {
    font-size: 14px;
    color: $bright-orange;
  }

  .color-wrap,
  .date-wrap,
  .datetime-local-wrap,
  .email-wrap,
  .file-wrap,
  .image-wrap,
  .month-wrap,
  .number-wrap,
  .password-wrap,
  .search-wrap,
  .submit-wrap,
  .tel-wrap,
  .text-wrap,
  .time-wrap,
  .url-wrap,
  .week-wrap {
    display: inline-block;
    border: 1px solid $black;
    width: 100%;
    max-width: 310px;
    background-color: $tan;

    input {
      @include autofill($tan, $black, 3.1em);
      font-size: inherit;
      padding: 1em;
      // Changing line-height from this may cause issues in Safari.
      line-height: 1.2;
      width: 100%;

      @include placeholder {
        color: $grey;
      }
    }
  }

  button[type='submit'],
  input[type='submit'] {
    @include inline-block;
    vertical-align: bottom;
    font-size: inherit;
    line-height: 1.2;
    padding: 1em;
    border: 1px solid $black;
    overflow: hidden;
  }
}

.subscribe-form {
  @include raleway-regular(18px);

  .single-line-wrapper {
    display: inline-block;
    position: relative;
    padding-right: 3.1em;

    .form-field.email_address {
      @include inline-block;

      label {
        visibility: hidden;
        position: absolute;
      }
    }

    button[type='submit'],
    input[type='submit'] {
      position: absolute;
      top: 0;
      height: 100%;
      right: 0;
      width: 3.1em;
      border-left: 0;
    }
  }
}
