@import 'c/main/src/constants';
@import 'c/main/src/type';
@import './books/mixins';

.home.page-banner {
  .page-main-header {
    z-index: 1;

    .banner-text {
      position: relative;

      @include tablet-portrait-and-phone {
        order: 1;
      }
    }

    .banner-image {
      position: relative;

      @include tablet-portrait-and-phone {
        order: 2;
      }
    }
  }
  .background {
    left: percentage(235px / $design-max);
    bottom: 0;
    @include decoration-dot('dot_pink.svg', $container: $design-max);
    // margin-bottom: -1%;
    transform: translateY(50%);
    z-index: -1;
  }
}

.homepage {
  $home-inner-width: $new-design-width;
  $home-inner-padding: ($desktop-inner - $home-inner-width) / 2;
  $intro-subheading-desktop-margin-top: $m3;
  clear: both;
  padding-top: 0 !important;

  section {
    @include desktop-and-tablet {
      margin: 0 0 percentage($m2 / $desktop-inner);
    }

    @include phone {
      margin: 0 0 percentage($m4 / $phone-inner);
    }
  }

  .intro-section {
    position: relative;
    text-align: center;

    @include desktop-and-tablet {
      margin-top: percentage($m4 / $desktop-inner);
    }

    @include phone {
      margin-top: percentage($m4 / $phone-inner);
    }
  }

  #animated-rainbow {
    $width: percentage(130px / $desktop-inner);
    @include decoration-animation($width);
    position: relative;
    margin: 0 auto;

    @include tablet {
      $width: percentage(130px / $tablet-inner);
      @include decoration-animation($width);
      position: relative;
      margin: 0 auto;
    }

    @include phone {
      $width: percentage(90px / $phone-inner);
      @include decoration-animation($width);
      position: relative;
      margin: 0 auto;
    }
  }

  .intro-heading {
    $intro-heading-width: 750px;
    max-width: $intro-heading-width;
    position: relative;
    width: percentage($intro-heading-width / $desktop-inner);
    margin: 0 auto;

    @include phone {
      width: 100%;
      max-width: 340px;
      margin-left: auto;
      margin-right: auto;
    }

    .background-1 {
      left: 0;
      top: 0;
      transform: translateY(-50%);
      @include decoration-dot('dot_lightblue.svg', $container: $intro-heading-width);
      margin-top: percentage(($dot-design-width * 2) / $intro-heading-width);
      margin-left: percentage(-$dot-design-width / $intro-heading-width);
    }

    .background-2 {
      @include decoration-dot('dot_red.svg', $container: $intro-heading-width);
      top: 0;
      margin-top: percentage((-$dot-design-width * 1.5) / $intro-heading-width);
      right: 0;
      margin-right: percentage((-$dot-design-width * 1.5) / $intro-heading-width);
    }

    .background-3 {
      @include decoration-dot('dot_lightgreen.svg', $container: $intro-heading-width);
      top: 0;
      margin-top: percentage((-$dot-design-width * 3) / $intro-heading-width);
      right: 0;
      margin-right: percentage((-$dot-design-width * 3) / $intro-heading-width);
    }

    .homepage-main-header,
    p {
      margin-left: auto;
      margin-right: auto;
    }

    .homepage-main-header {
      @include raleway-regular;
      @include responsive-font-size(48px, 20px);
      margin-top: 0;
      position: relative;

      @include desktop-and-tablet {
        margin-bottom: percentage($m3 / $desktop-inner);
      }

      @include phone {
        margin-top: 1em;
        margin-bottom: percentage($m4 / $phone-inner);
      }

      h1 {
        @include raleway-regular(48px, 22px);
        line-height: 1.25;
        letter-spacing: 1px;

        @include phone {
          @include raleway-regular(28px);
          line-height: 29.5px;
          max-width: 300px;
          margin: 0 auto;
        }
      }
    }

    p {
      @include raleway-bold(19px, 17px);
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 0;
      letter-spacing: .05em;

      a {
        @include default-anchor-style($weight: 3px, $offset: 0);
        padding-bottom: 5px;
      }
    }
  }

  .intro-subheading {
    position: relative;
    width: 100%;

    @include desktop-and-tablet {
      margin: percentage($intro-subheading-desktop-margin-top / $desktop-inner) 0 0;
    }

    @include phone {
      margin: percentage($m5 / $phone-inner) 0 0;
    }

    @include phone-portrait {
      max-width: 275px;
      margin-left: auto;
      margin-right: auto;
    }

    &::before {
      $aspect-ratio: 60px / 57px;
      top: 0;
      left: 0;

      @include desktop-and-tablet {
        $width: percentage(57px / $desktop-inner);
        @include decoration($width, $aspect-ratio, 'dot7.svg');
        margin-top: percentage(-30px / $desktop-inner);
        margin-left: percentage(-($desktop-margin + 10px) / $desktop-inner);
      }

      @include phone {
        $width: percentage(30px / $phone-inner);
        @include decoration($width, $aspect-ratio, 'dot7.svg');
        margin-top: percentage(-30px / $phone-inner);
        margin-left: percentage(-($phone-margin + 5px) / $phone-inner);
      }
    }

    &::after {
      $aspect-ratio: 59px / 41px;
      top: 0;
      right: -1px;

      @include desktop-and-tablet {
        $width: percentage(41px / $desktop-inner);
        @include decoration($width, $aspect-ratio, 'dot1.svg');
        margin-right: percentage(-$desktop-margin / $desktop-inner);
      }

      @include phone {
        $width: percentage(20px / $phone-inner);
        @include decoration($width, $aspect-ratio, 'dot1.svg');
        margin-right: percentage(-$phone-margin / $phone-inner);
      }
    }

    h3 {
      @include raleway-bold;
      @include responsive-font-size(46px, 27px);
      position: relative;
      line-height: 1.3;
      margin-top: 0;
      max-width: 930px;
      margin-left: auto;
      margin-right: auto;

      @include desktop-and-tablet {
        margin-bottom: percentage($m3 / $desktop-inner);
      }

      @include phone {
        font-weight: 600;
        margin-bottom: percentage($m3 / $phone-inner);
      }
    }

    p {
      position: relative;
      @include raleway-bold(19px, 17px);

      a {
        position: relative;
        display: inline-block;
        padding: 15px 50px;
        border: 1px solid $black;
        width: 100%;
        max-width: 250px;
        letter-spacing: .05em;
        text-transform: uppercase;
        background: $tan;

        @include hover {
          background-color: $black;
          color: $tan;
        }
      }
    }
  }

  .available-books-section {
    .background-1,
    .background-2,
    .background-3,
    .background-4 {
      display: none;
    }

    .product-carousel {
      @include desktop-and-tablet {
        margin-top: 50px;
      }

      @include tablet-portrait-and-phone {
        display: flex;
        flex-wrap: wrap;
        white-space: normal;

        &::after {
          // disable flickity
          content: '';
        }
      }

      @include phone {
        margin-top: $m4;
      }

      a.book-carousel-item {
        @include hover {
          .text p {
            color: transparentize($black, 1 - $hover-opacity);
          }
        }

        &.current-page {
          .text p {
            color: transparentize($black, 1 - $hover-opacity);
          }

          &.related-hovered {
            .text p {
              color: $black;
            }
          }
        }
      }
    }
  }

  .non-book-products-section {
    @include phone {
      margin-top: 45px;
    }

    .product-carousel {
      .carousel-item {
        .text {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  .subscribe-section {
    position: relative;

    $subscribe-desktop-margin-top: $m5;

    @include desktop-and-tablet {
      margin: percentage($subscribe-desktop-margin-top / $desktop-inner) 0 0;
    }

    .subscribe-heading {
      @include raleway-bold(28px, 20px);
      line-height: 1.1;
      text-align: center;
      margin: 0 0 .5em;
    }

    .subscribe-subheading {
      @include responsive-font-size(20px, 18px);
      line-height: .8;
      text-align: center;
      margin: 0 0 percentage($m5 / $desktop-inner);
    }

    .subscribe-form {
      position: relative;
      text-align: center;

      .errorlist,
      .email-wrap {
        max-width: 220px;
        text-align: left;
      }

      button[type='submit'],
      input[type='submit'] {
        background-color: $yellow;
        // Invisible text rather than no text, for screen readers
        color: transparent;
        background-image: url('../../../../i/mbox.svg');
        background-repeat: no-repeat;
        background-size: 65%;
        background-position: center;

        @include hover {
          opacity: $hover-opacity;
        }
      }

      .message {
        margin: .5em 0 0;
      }
    }
  }

  .podcasts-section {
    $podcasts-section-width: $home-inner-padding + $home-inner-width;
    position: relative;

    .background-1 {
      @include decoration-dot('dot_pink.svg');
      left: percentage(100px / $desktop-inner);
      bottom: percentage($dot-design-width / $desktop-inner);
    }

    .background-2 {
      @include decoration-dot('dot_yellow.svg');
      bottom: 0;
      left: percentage(820px / $desktop-inner);
      margin-bottom: percentage(-$dot-design-width / $desktop-inner);
    }

    .background-3 {
      @include decoration-dot('dot_lightgreen.svg');
      bottom: 0;
      left: percentage(780px / $desktop-inner);
      margin-bottom: percentage(-($dot-design-width * 3) / $desktop-inner);
    }

    .podcasts-section-heading-wrap {
      $podcasts-heading-margin: 17px;
      $podcasts-title-width: ($podcasts-section-width - ($podcasts-heading-margin * 2)) / 3;

      @include desktop-and-tablet {
        @include clearfix;
        width: percentage($podcasts-section-width / $desktop-inner);
      }

      .section-heading,
      .podcasts-intro {
        display: inline-block;
      }

      .section-heading {
        width: percentage($podcasts-title-width / $podcasts-section-width);
        margin-right: percentage($podcasts-heading-margin / $podcasts-section-width);
      }

      .podcasts-intro {
        @include feijoa(24px, 20px);
        line-height: 1.25;
        position: relative;

        @include desktop-and-tablet {
          width: percentage(($podcasts-section-width - $podcasts-title-width - $podcasts-heading-margin) / $podcasts-section-width);
          float: right;
          margin: 0 auto;
          padding-top: ($m6 / 24px) * 1em;
        }

        @include phone {
          margin: $m5 0;
        }

        .podcast-listen-nav-heading {
          @include raleway-bold;
          font-size: 15px;
          text-transform: uppercase;
          margin: 2.25em 0 0.75em;
          letter-spacing: 0.075em;
        }

        .podcast-listen-nav {
          display: flex;
          align-items: center;

          a {
            display: inline-block;
            margin-right: 1.5em;

            @include hover {
              opacity: $hover-opacity;
            }

            &:last-child {
              margin-right: 0;
            }
          }

          img {
            height: 2em;
            width: auto;

            &.rova {
              height: 1.25em;
            }
          }
        }
      }
    }

    .podcast-carousel {
      @include desktop-and-tablet-landscape {
        margin-top: percentage($m3 / $desktop-inner);
      }
      @include tablet-portrait-and-phone {
        clear: both;
      }
    }
  }

  .scrapbook-section {
    .section-heading {
      max-width: 590px;

      @include tablet-and-phone {
        margin-bottom: 1em;
      }
    }
  }

  .awards-and-features-section {
    position: relative;

    @include desktop-and-tablet-landscape {
      $extra: percentage($desktop-margin / $desktop-inner);
      margin-left: -$extra;
      width: 100% + $extra;
    }

    .section-heading {
      margin: 0 0 2em;

      @include desktop-and-tablet-landscape {
        margin-left: percentage($desktop-margin / ($desktop-inner + $desktop-margin));
      }

      @include tablet-portrait-and-phone {
        margin-left: 0;
      }
    }
  }

  .book-creation-section {
    position: relative;

    @include desktop-and-tablet {
      // Client design review measures margin below this to top of rainjump
      // (.selling-points-section::before). 100px is the height of rainjump,
      // the margin itself is on .selling-points-section.
      margin: percentage($m3 / $desktop-inner) 0
              percentage(100px / $desktop-inner);
    }

    @include phone {
      margin: $m5 0 0;
    }

    .background {
      $aspect-ratio: 468px / 147px;
      $width: percentage(147px / $desktop-inner);
      position: absolute;
      background-image: url('../../../../i/backgrounds/create.svg');
      background-repeat: no-repeat;
      width: $width;
      background-size: 100%;
      height: 0;
      padding-top: $width * $aspect-ratio;
      z-index: -2;
      bottom: 0;
      left: 0;
      margin-left: percentage(-125px / $desktop-inner);
    }
  }

  .selling-points-section {
    position: relative;

    $selling-points-desktop-margin-top: $m2;

    @include desktop-and-tablet {
      margin: percentage($selling-points-desktop-margin-top / $desktop-inner) 0 0;
    }

    @include phone {
      margin: $m5 0 0;
    }

    &::before {
      $aspect-ratio: 117px / 253px;
      top: 0;
      right: 0;

      @include desktop-and-tablet {
        $width: percentage(253px / $desktop-inner);
        @include decoration($width, $aspect-ratio, 'rainjump.svg');
        margin-top: percentage(-25px / $desktop-inner);
        margin-right: percentage(115px / $desktop-inner);
      }

      @include phone {
        display: none;
      }
    }

    .background-1 {
      $aspect-ratio: 199px / 215px;
      top: 0;
      right: -1px;

      @include desktop-and-tablet {
        $width: percentage(215px / $desktop-inner);
        @include background($width, $aspect-ratio, 'sellingpoints.svg');
        margin-top: percentage(-150px / $desktop-inner);
        margin-right: percentage(-$desktop-margin / $desktop-inner);
      }

      @include phone {
        display: none;
      }
    }

    .background-2 {
      $aspect-ratio: 371px / 334px;
      top: 0;
      right: -1px;

      @include desktop-and-tablet {
        $width: percentage(334px / $desktop-inner);
        @include background($width, $aspect-ratio, 'sellingpoints2.svg');
        z-index: -3;
        margin-top: percentage(-355px / $desktop-inner);
        margin-right: percentage(-$desktop-margin / $desktop-inner);
      }

      @include phone {
        display: none;
      }
    }

    .colourful-carousel {
      @include desktop-and-tablet {
        margin: percentage($m2 / $desktop-inner) 0 0;
      }

      @include phone {
        margin: $m5 0 0;
      }
    }
  }

  .experts-section {
    $experts-desktop-margin-top: $m2;
    $expert-section-width: $home-inner-padding + $home-inner-width;
    $expert-section-margin: 17px;
    $expert-section-col-width: ($expert-section-width - ($expert-section-margin * 2)) / 3;
    $expert-section-right-width: ($expert-section-col-width * 2) + $expert-section-margin;
    position: relative;
    margin-right: auto;

    @include desktop-and-tablet {
      width: percentage($expert-section-width / $desktop-inner);

      > .background {
        @include decoration-dot('dot_lightblue.svg', $container: $expert-section-width);
        right: -2em;
        bottom: -6em;
      }
    }

    .section-heading,
    .experts-intro {
      @include desktop-and-tablet {
        display: inline-block;
      }
    }

    .section-heading {
      width: percentage($expert-section-col-width / $expert-section-width);
      margin-right: percentage($expert-section-margin / $expert-section-width);
      @include desktop-and-tablet {
        vertical-align: top;
      }

      @include phone {
        width: 100%;
        padding-right: 3em;
        max-width: none;
        min-height: 2em;

        &::after {
          content: '';
          display: block;
          position: absolute;
          background-image: url('../../../../i/research.png');
          background-repeat: no-repeat;
          background-size: 100%;
          width: 2.875em;
          height: 0;
          padding-top: 50%;
          top: 0;
          right: 0;

          @include phone {
            top: 15px;
          }
        }
      }
    }

    .experts-intro {
      @include feijoa(24px, 20px);
      line-height: 1.25;
      position: relative;

      @include desktop-and-tablet {
        $expert-intro-width: $expert-section-width - $expert-section-col-width - $expert-section-margin;
        width: percentage($expert-intro-width / $expert-section-width);
        float: right;
        margin: 0 auto;
        padding-top: ($m6 / 24px) * 1em;
      }

      @include phone {
        margin: 45px auto 0;
      }
    }

    .expert-profiles {
      $research-logo-width: 235px;
      display: inline-block;
      position: relative;
      justify-content: space-evenly;
      clear: both;
      margin: percentage(($research-logo-width / 2) / $desktop-inner) 0 0;
      width: 100%;

      .research-logo {
        width: percentage($research-logo-width / $desktop-inner);
        position: absolute;
        right: 0;
        z-index: 1;
        transform: translate(50%, -50%);

        @include phone {
          display: none;
        }
      }

      .expert {
        display: flex;
        margin-bottom: 2em;

        @include phone {
          flex-wrap: wrap;
          flex-direction: column;
          margin-bottom: 4em;
        }

        .expert-bio {
          @include desktop-and-tablet {
            width: percentage($expert-section-col-width / $expert-section-width);
            margin-right: percentage($expert-section-margin / $expert-section-width);

            &:nth-child(3) {
              margin-right: 0;
            }
          }

          @include phone {
            width: 100%;
            order: 2;
          }

          .expert-name {
            @include raleway-bold(22px, 18px);
            line-height: 1.42;
            margin: 1em 0 0;

            @include desktop-and-tablet {
              margin: 0;
            }
          }

          .expert-experience {
            @include raleway-regular(22px, 18px);
            line-height: 1.18;
            margin: 0 0 1em;
          }

          .load-expert-about {
            display: inline-block;
            .load {
              font-weight: 600;
              line-height: 24px;
              letter-spacing: 0;
            }
          }

          .expert-about {
            @include raleway-regular(18px);
            line-height: 1.2;
            display: none;

            &.visible {
              display: block;
            }
          }
        }

        .expert-carousel-wrap {
          @include desktop-and-tablet {
            width: percentage($expert-section-right-width / $expert-section-width);
            display: flex;

            &::after {
              content: '';
              display: none;
            }
          }

          @include phone {
            margin-bottom: 2em;
          }

          .expert-quote-wrap,
          .expert-portrait-wrap {
            @include desktop-and-tablet {
              width: percentage($expert-section-col-width / $expert-section-right-width);
            }
            @include phone {
              min-height: 60vh;
            }
          }

          .expert-quote-wrap > .expert-quote,
          .expert-portrait-wrap > .image {
            border-radius: 18px;
            @include phone {
              min-height: 60vh;
            }
          }

          .expert-quote-wrap {
            @include desktop-and-tablet {
              margin-right: percentage($expert-section-margin / $expert-section-right-width);
              order: 1;
            }

            @include phone {
              width: 100%;
              height: 100%;
              padding-left: 10%;
              padding-right: 10%;
            }

            .expert-quote {
              display: flex;
              flex-direction: column;
              justify-content: center;
              position: relative;
              height: 100%;

              .text-wrap {
                @include feijoa(24px, 16px);
                line-height: 1.2;
                padding: 1.2em;
                color: $white;
                text-align: center;
                z-index: 2;
                width: 100%;
                max-width: 370px;
                margin: 0 auto;
                white-space: normal;

                @include phone {
                  @include feijoa(20px);
                  padding-top: $m4;
                  padding-bottom: $m4;
                }

                p {
                  width: 100%;
                  margin: 0;
                }

                & > .name {
                  font-style: italic;
                  margin: 2em 0 0;
                }
              }
            }
          }

          .expert-portrait-wrap {
            @include desktop-and-tablet {
              order: 2;
            }

            @include phone {
              width: 100%;
              padding: 0 10%;
            }

            .aspect-maintainer {
              @include phone {
                height: auto;
                position: initial;
                img {
                  height: 100%;
                  width: auto;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }
          }

          .flickity-prev-next-button {
            display: none;
          }
        }

        $expert-col-design-width: 357px;
        $expert-col-design-height: 500px;

        &.expert-1 {
          > .background {
            top: 0;
            left: percentage(($expert-section-col-width + 60px) / $expert-section-width);
            @include decoration-dot('dot_white.svg', $container: $expert-section-width);
            transform: translateY(-40%);
            z-index: 1;
          }

          .expert-carousel-wrap {
            .expert-quote {
              &::before {
                $aspect-ratio: 75px / 48.5px;
                $width: percentage(75px / $expert-section-col-width);
                content: '';
                @include decoration($width, $aspect-ratio, 'cloudtears.svg');
                bottom: 100%;
                right: 0;
                top: 0;
                margin-top: percentage(39px / $expert-section-col-width);
                margin-right: percentage(47px / $expert-section-col-width);
                z-index: 1;
              }
            }
          }
        }

        &.expert-2 {
          .expert-bio {
            position: relative;
            .background-1 {
              bottom: percentage(135px / $expert-col-design-height);
              left: percentage(200px / $expert-col-design-width);
              @include decoration-dot('dot_yellow.svg',
                       $container: $expert-section-col-width);
            }

            .background-2 {
              bottom: percentage(100px / $expert-col-design-height);
              left: percentage(255px / $expert-col-design-width);
              @include decoration-dot('dot_darkgreen.svg',
                       $container: $expert-section-col-width);
            }
          }

          .expert-carousel-wrap {
            .expert-quote {
              &::after {
                $aspect-ratio: 75px / 63.68px;
                $width: percentage(65.3px / $expert-section-col-width);
                content: '';
                @include decoration($width, $aspect-ratio, 'rainbowcloud.svg');
                left: 0;
                bottom: 0;
                margin-bottom: percentage(77.38px / $expert-section-col-width);
                z-index: 1;

                @include phone {
                  // display: none;
                }
              }
            }

            .expert-portrait-wrap {
              &::after {
                bottom: 0;
                right: -3em;
                @include decoration-dot('dot_lightblue.svg',
                $container: $expert-section-right-width);
                margin-bottom: -4em;
              }
            }
          }
        }
      }
    }
  }

  .reviews-section {
    position: relative;

    @include desktop-and-tablet {
      margin: 0 0 percentage($m1 / $desktop-inner);
    }

    .background-1 {
      @include decoration-dot('dot_pink.svg');
      top: -7em;
      left: percentage(300px / $desktop-inner);
    }

    .background-2 {
      @include decoration-dot('dot_red.svg');
      top: -2em;
      left: percentage(1005px / $desktop-inner);
    }

    .background-3 {
      @include decoration-dot('dot_lightblue.svg');
      top: 3em;
      left: percentage(960px / $desktop-inner);
    }

    .reviews-carousel-wrap {
      position: relative;
    }

    .section-heading {
      margin-bottom: percentage($m3 / $desktop-inner);
    }

    .reviews-carousel {
      @include phone {
        margin: $m5 0;
      }

      .flickity-prev-next-button {
        @include phone {
          display: none;
        }
      }

      .flickity-page-dots {
        position: relative;

        @include phone {
          margin-bottom: percentage($m2 / $phone-inner);
        }

        .dot {
          &:not(.is-selected) {
            background: transparent;
          }
        }
      }
    }
  }
}
