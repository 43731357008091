@import '../constants';
@import '../type';

.stamped-product-reviews-badge {
  .stamped-badge {
    @include raleway-regular(23px, 19px);

    @include phone {
      display: flex;
      flex-direction: column-reverse;
    }

    .stamped-badge-caption {
      line-height: 1.2;
    }

    .stamped-badge-starrating {
      margin-right: 13px !important;
    }

    .stamped-fa-star {
      color: $dark-red;
      &::before {
        font-size: 26px !important;
      }
    }
  }
}

.stamped-fa-star-o {
  color: $dark-red;
  &::before {
    font-size: 26px;
  }
}

#stamped-main-widget {
  margin: 0;

  div.stamped-container[data-widget-style],
  div[data-widget-style] div.stamped-container {
    margin: 0 !important;
    max-width: none;
  }

  $section-heading-font-size: 67px;

  .stamped-container {
    position: relative;
  }

  .stamped-header-title {
    @include raleway-bold($section-heading-font-size, 40px);
    position: relative;
    line-height: .9;
    padding-top: ($m6 / $section-heading-font-size) * 1em;
    padding-bottom: .1em;
    width: (475px / $section-heading-font-size) * 1em;
    max-width: 100%;
    margin: 0;
    text-align: left !important;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: (80px / $section-heading-font-size) * 1em;
      $border-size: (5px / $section-heading-font-size) * 1em;
      border-top: $border-size solid;

      @include phone {
        border-width: 4px;
      }
    }
  }

  .stamped-header {
    margin-bottom: .5em;

    .stamped-summary {
      position: relative;
      margin: percentage($m4 / $desktop-inner) 0 0;

      @include phone {
        margin: $m5 0 0;
      }

      .summary-overview {
        margin-bottom: 0;

        .stamped-summary-caption-1,
        .stamped-summary-caption-2 {
          display: none !important;
        }

        .stamped-summary-starrating {
          .stamped-fa-star {
            color: $dark-red;
          }
        }
      }

      .stamped-summary-actions {
        display: none;
      }
    }
  }

  [data-widget-type=minimal] {
    .summary-overview {
      float: left !important;
      width: auto !important;
    }

    .stamped-summary-starrating {
      margin: 0 !important;
    }
  }

  .stamped-content {
    position: relative;

    .stamped-tab-container {
      ul.stamped-tabs {
        border-bottom: 0;

        #tab-reviews {
          @include raleway-bold(26px, 18px);
          color: $black !important;
          line-height: 1.17;
          letter-spacing: 1.54px;
          margin: .5em 0 0;
          padding: 0;
          border: 0 !important;
          display: inline;
          padding-left: 1em;
          position: relative;

          @include phone {
            font-size: 18px !important;
            margin-bottom: $m5;
          }

          &::before {
            @include raleway-bold(26px, 18px);
            content: '' attr(data-count) "\00a0";
            color: $black;
            line-height: 1;
            letter-spacing: 1.54px;
            display: inline;
            position: absolute;
            margin-left: -0.85em;
            transform: translateY(-50%);
            top: 50%;
          }

          &::after {
            content: '';
            padding: 0;
            margin: 0;
            background: none;
          }
        }

        .stamped-widget-poweredby {
          display: none;
        }
      }
    }

    .stamped-messages,
    .stamped-reviews-filter {
      display: none;
    }

    .stamped-reviews {
      padding-top: percentage($m3 / $desktop-inner);
      margin-bottom: percentage($m4 / $desktop-inner);
      margin-left: percentage(95px / $desktop-inner);

      @include phone {
        margin-left: 0;
      }

      .stamped-review {
        $review-list-design-width: 1105px;
        padding: 2em 0;
        margin-bottom: 0;

        &:first-child {
          padding-top: 3em;
        }

        &:last-child {
          border-bottom: 1px solid $black;
        }

        @include desktop-and-tablet {
          display: flex;
          border-top: 1px solid $black;
        }

        @include phone {
          display: block;
          border-bottom: 1px solid $black;
          margin-bottom: 0;
          padding-bottom: 2em;

          &:nth-child(n+4) {
            display: none;
          }
        }

        $review-margin: 45px;
        $author-date-rating-width: 215px;
        $review-body-width: $review-list-design-width - $review-margin - $author-date-rating-width;

        .stamped-review-header {
          width: percentage($author-date-rating-width / $review-list-design-width);
          margin-right: percentage($review-margin / $review-list-design-width);

          @include desktop-and-tablet {
            display: flex;
            flex-direction: column;
          }

          @include phone {
            width: 100%;
            display: block;
            margin-right: 1em;
            border: 0;
          }

          .stamped-verified-badge,
          .stamped-review-avatar,
          .review-location,
          .review-location + div {
            display: none;
          }

          .author,
          .created {
            @include raleway-bold(19px, 18px);
            color: $black;
            line-height: 1.17;
            letter-spacing: 1.54px;
            margin: 0 0 .5em;
            display: inline;
          }

          .author {
            float: left;
            order: 1;
            &::after {
              content: "\00a0";
            }
          }

          .created {
            float: none !important;
            order: 2;
          }

          .stamped-review-header-starratings {
            clear: both;
            display: block;
            margin-bottom: 1em;
            order: 3;

            @include phone {
              margin-top: 0;
            }

            .stamped-fa-star {
              &::before {
                font-size: 26px;
                color: $dark-red;
              }
            }
          }
        }

        .stamped-review-content {
          width: percentage($review-body-width / $review-list-design-width);
          margin-left: 0 !important;

          @include phone {
            width: 100%;
          }

          .stamped-review-body {
            @include phone {
              width: 100%;
              display: block;
              margin-right: 1em;
              padding-top: 0;
              padding-right: 1em;
            }

            .stamped-review-header-title,
            .stamped-review-image,
            .stamped-review-product,
            .stamped-review-variant,
            .stamped-review-reply {
              display: none !important;
            }

            .stamped-review-content-body,
            .stamped-review-footer {
              @include feijoa(18px);
              line-height: 1.33;
              color: $black;
              display: inline-block;
            }

            .stamped-review-content-body {
              width: percentage(472px / $review-body-width);
              margin-right: percentage(29px / $review-body-width);

              @include phone {
                width: 100%;
                margin-right: 0;
                padding-right: 1em;
              }
            }

            .stamped-review-footer {
              vertical-align: top;
              .stamped-review-share {
                display: none;
              }
              .stamped-review-vote {
                @include phone {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
