@import 'c/main/src/constants';
@import 'c/main/src/type';

.section-heading {
  $section-heading-font-size: 67px;
  @include raleway-bold($section-heading-font-size, 40px);
  position: relative;
  line-height: .9;
  padding-top: ($m6 / $section-heading-font-size) * 1em;
  width: (475px / $section-heading-font-size) * 1em;
  max-width: 400px;
  margin: 0;

  &::before {
    $section-heading-font-size: 67px;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: (80px / $section-heading-font-size) * 1em;
    $border-size: (5px / $section-heading-font-size) * 1em;
    border-top: $border-size solid;

    @include phone {
      border-width: 4px;
    }
  }
}

.section-anchor {
  content: '';
  display: block;
  position: absolute;
  width: 100%;

  @include desktop-tablet-and-phone-landscape {
    @include responsive-font-size(
      $main-heading-design-size, $main-heading-phone-start-size);
    height: 4.5em;
    margin-top: -4.5em;
  }

  @include phone-portrait {
    @include responsive-font-size(
      $main-heading-phone-start-size, $main-heading-phone-end-size,
      $start-shrinking-at: $phone-portrait);
      height: 4.75em;
      margin-left: -4.75em;
  }
}

.page-banner {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: -100vw;
    right: -100vw;
    bottom: 0;
    top: -100vw;
    display: block;
    background: currentColor;
    z-index: 1;
  }

  .page-main-header {
    position: relative;
    z-index: 2;
    margin: 0;
    display: flex;

    @include tablet-portrait-and-phone {
      flex-direction: column;
    }

    .banner-text,
    .banner-image {
      @include desktop-and-tablet-landscape {
        width: 50%;
      }
    }

    .banner-image {
      display: block;
      
      @include tablet-portrait-and-phone {
        order: 1;
      }
    }

    .banner-text {
      padding: percentage(57px / $desktop-inner);
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;

      @include tablet-portrait-and-phone {
        order: 2;
        margin-left: 0;
        padding-top: 30px;
      }

      .title,
      .text {
        @include desktop-and-tablet-landscape {
          width: percentage(412px / ($desktop-inner / 2));
        }
      }

      .title {
        @include raleway-regular(48px, 30px);
        line-height: 50px;
        letter-spacing: 2.02px;
        margin: 0;
      }

      .text {
        @include raleway-regular(24px, 20px);
        line-height: 1.3;
        margin: 1.1em 0 0;
      }

      .button-link {
        @include button-link;
        width: percentage(220px / ($desktop-inner / 2));
        min-width: 200px;
        background: $white;
        margin-top: 4em;

        @include tablet-portrait-and-phone {
          width: percentage(218px / 333px);
          margin: $m4 0 $m5;
        }

        span {
          color: $black;
        }

        @include hover {
          background: $black;
          span {
            color: $white;
          }
        }
      }
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.load-link {
  text-align: center;
  display: block;
  @include hover {
    cursor: pointer;
  }
  @include desktop-and-tablet-landscape {
    display: none;
  }
  .load {
    @include raleway-bold(14px);
    @include default-anchor-style($weight: 2px, $offset: 0px);
    text-transform: uppercase;
    line-height: 20px;
    letter-spacing: 2px;
    display: inline-block;
    margin: 0;
  }
}

.available-books-section {
  position: relative;
  @include desktop-and-tablet-landscape {
    margin: percentage($m2 / $desktop-inner) 0;
  }

  @include tablet-portrait-and-phone {
    margin: $m4 0;
  }

  .background-1 {
    @include decoration-dot('dot_white.svg');
    top: 0;
    left: 0;
    margin-top: percentage((-$dot-design-width * 1.5) / $desktop-inner);
    margin-left: percentage($dot-design-width / $desktop-inner);
  }

  .background-2 {
    @include decoration-dot('dot_lightgreen.svg');
    top: 0;
    right: 0;
    margin-right: percentage(($dot-design-width * 1.5) / $desktop-inner);
  }

  .background-3 {
    @include decoration-dot('dot_white.svg');
    top: percentage(($dot-design-width * 5) / $desktop-inner);
    right: 0;
    margin-right: percentage(($dot-design-width * 2) / $desktop-inner);
  }

  .background-4 {
    @include decoration-dot('dot_lightblue.svg');
    bottom: 0;
    right: 0;
    margin-bottom: percentage((-$dot-design-width * .5) / $desktop-inner);
  }

  .section-heading {
    @include tablet-portrait-and-phone {
      margin-bottom: 1em;
      width: 60%;
    }
  }

  .image {
    @include force-square-image;
  }
}
